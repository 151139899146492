import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "../../models/auth.model";
import { useNavigate } from "react-router-dom";
import resolver from "../../resolver/auth.resolver";
import { Alert, Snackbar } from "@mui/material";
import isEmpty from "../../utils/isempty.utils";
import AuthService from "../../services/api/auth.service";
import { getMe } from "../../actions/API/user.action";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Auth>({ resolver });

  const [openFailed, setOpenFailed] = useState(false);
  const [message, setMessage] = useState("");
  const userConnected = AuthService.getUserConnected();
  const user = useSelector((state: any) => state.userReducer);

  useEffect(() => {
    if (!isEmpty(userConnected)) {
      navigate("/dashboard");
    }
  }, [userConnected]);

  function access(arr: string[]): boolean {
    return arr.some((value) => value === "ADMIN" || value === "SUPER_ADMIN");
  }

  const onSubmit = handleSubmit((data) => {
    AuthService.login(data)
      .then((r) => {
        if (r.status === 200) {
          if (access(r.data.roles)) {
            sessionStorage.setItem("token", r.data.currentHashedRefreshToken);
            dispatch(getMe(r.data.currentHashedRefreshToken));
            setMessage("Login Success");
          }
        } else {
          setMessage("Login or Password not found");
        }
      })
      .catch((r) => {
        setOpenFailed(true);
        setMessage("Login or Password not found");
      });
  });

  const redirectToForgotPassword = () => {
    navigate("/");
  };

  const handleCloseSnackbar = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  return (
    <div className="view-login">
      <img className="app-logo" src="/assets/logo/logo.png" alt="" />
      <div className="body">
        <div className="left-part">
          <div className="title">Hi, Welcome back</div>
          <img className="login-img" src="/assets/images/login.png" alt="" />
        </div>
        <div className="right-part">
          <div className="title">Sign In to Dashboard</div>
          <form onSubmit={onSubmit} className="container-form">
            <input className="input-form" {...register("login")} placeholder={"Username"} type="text" />
            {errors?.login && <p className="error-message">{errors.login.message}</p>}
            <input className="input-form" placeholder={"Password"} {...register("password")} type="password" />
            {errors?.password && <p className="error-message">{errors.password.message}</p>}
            <div className="button-reset" onClick={() => redirectToForgotPassword()}>
              forgot password ?
            </div>
            <button className="button-submit">Login</button>
          </form>
          <img className="login-img-2" src="/assets/images/login-2.svg" alt="" />
        </div>
      </div>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
