

export const GET_LANG = "GET_LANG";


export const getLANG = (lang) => {
  return (dispatch) => {
    if(lang === "FR"){
      return dispatch({ type: GET_LANG, payload: "FR" });
    }else{
      return dispatch({ type: GET_LANG, payload: "ANG" });
    }
  };
};