export enum LogApiVersionEnum {
  V_0_0_1 = "V_0.0.1",
  V_0_0_2 = "V_0.0.2",
  V_0_0_3 = "V_0.0.3",
  V_0_0_4 = "V_0.0.4",
  V_0_0_5 = "V_0.0.5",
  V_0_0_6 = "V_0.0.6",
  V_0_0_7 = "V_0.0.7",
  V_0_0_8 = "V_0.0.8",
  V_0_0_9 = "V_0.0.9",
  V_0_1_0 = "V_0.1.0",
  V_0_1_1 = "V_0.1.1",
  V_0_1_2 = "V_0.1.2",
  V_0_1_3 = "V_0.1.3",
  V_0_1_4 = "V_0.1.4",
  V_0_1_5 = "V_0.1.5",
  V_0_1_6 = "V_0.1.6",
  V_0_1_7 = "V_0.1.7",
  V_0_1_8 = "V_0.1.8",
  V_0_1_9 = "V_0.1.9",
  V_1_0_0 = "V_1.0.0",
}
