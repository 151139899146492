import React, { SetStateAction, useState } from "react";
import CardUserMinimal from "../../components/card_user_minimal/CardUserMinimal";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FolderIcon from "@mui/icons-material/Folder";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Collapse, ListItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import BiotechIcon from "@mui/icons-material/Biotech";
import MailIcon from "@mui/icons-material/Mail";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import WorkIcon from "@mui/icons-material/Work";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
const DrawerNav = () => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleListItemClick = (event: any, index: SetStateAction<number>) => {
    setSelectedIndex(index);
  };
  return (
    <div className="component-drawer-nav">
      <img src="/assets/logo/logo.png" style={{ width: 50, height: 50, borderRadius: 10, marginBottom: 20 }} alt="" />
      <CardUserMinimal />
      <div className="title-nav">GENERAL</div>
      <List>
        <ListItem key={"App"} disablePadding>
          <Link to={"/dashboard"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <SpaceDashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"App"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Finance"} disablePadding>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={handleClick}
            sx={{
              "&$selected": {
                backgroundColor: "rgba(145, 158, 171, 0.12)",
                color: "rgb(34, 10, 110)",
                borderRadius: 3,
                "& .MuiListItemIcon-root": {
                  color: "rgb(34, 10, 110)",
                },
              },
              "&:hover": {
                backgroundColor: "rgba(145, 158, 171, 0.12)",
                color: "rgb(34, 10, 110)",
                borderRadius: 3,
                "& .MuiListItemIcon-root": {
                  color: "rgb(34, 10, 110)",
                },
              },
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary={"Finance"} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={"/product"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
              <ListItemButton
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}
                sx={{
                  pl: 4,
                  "&$selected": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <ProductionQuantityLimitsIcon />
                </ListItemIcon>
                <ListItemText primary="Produit" />
              </ListItemButton>
            </Link>
            <Link to={"/price"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
              <ListItemButton
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
                sx={{
                  pl: 4,
                  "&$selected": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Prix" />
              </ListItemButton>
            </Link>
            <Link to={"/subscriptions"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
              <ListItemButton
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
                sx={{
                  pl: 4,
                  "&$selected": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <CardMembershipIcon />
                </ListItemIcon>
                <ListItemText primary="Abonnement" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItem key={"Log"} disablePadding>
          <Link to={"/log"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <BiotechIcon />
              </ListItemIcon>
              <ListItemText primary={"Log"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Mail"} disablePadding>
          <Link to={"/mail"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={"Mail"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Health Check"} disablePadding>
          <Link to={"/health-check"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 7}
              onClick={(event) => handleListItemClick(event, 7)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <MonitorHeartIcon />
              </ListItemIcon>
              <ListItemText primary={"Health Check"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
      <div className="title-nav">MANAGEMENT</div>
      <List>
        <ListItem key={"Enterprise"} disablePadding>
          <Link to={"/enterprise"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 8}
              onClick={(event) => handleListItemClick(event, 8)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary={"Enterprise"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Utilisateurs"} disablePadding>
          <Link to={"/users"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 9}
              onClick={(event) => handleListItemClick(event, 9)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary={"Utilisateurs"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Cards"} disablePadding>
          <Link to={"/cards"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 10}
              onClick={(event) => handleListItemClick(event, 10)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary={"Cards"} />
            </ListItemButton>
          </Link>
        </ListItem>
        {/*
          * <ListItem key={"Address"} disablePadding>
          <Link to={"/address"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
                selected={selectedIndex === 11}
                onClick={(event) => handleListItemClick(event, 11)}
                sx={{
                  "&$selected": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                }}
            >
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary={"Address"} />
            </ListItemButton>
          </Link>
        </ListItem>
          * */}
        <ListItem key={"Group"} disablePadding>
          <Link to={"/group"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 12}
              onClick={(event) => handleListItemClick(event, 12)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText primary={"Group"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Média"} disablePadding>
          <Link to={"/media"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 13}
              onClick={(event) => handleListItemClick(event, 13)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <PermMediaIcon />
              </ListItemIcon>
              <ListItemText primary={"Média"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Occupation"} disablePadding>
          <Link to={"/occupation"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 14}
              onClick={(event) => handleListItemClick(event, 14)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary={"Occupation"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Profile"} disablePadding>
          <Link to={"/profile-g"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 15}
              onClick={(event) => handleListItemClick(event, 15)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <ContactPageIcon />
              </ListItemIcon>
              <ListItemText primary={"Profile"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Social-Network"} disablePadding>
          <Link to={"/social-network"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 16}
              onClick={(event) => handleListItemClick(event, 16)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <Diversity3Icon />
              </ListItemIcon>
              <ListItemText primary={"Social-Network"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>

      <div className="title-nav expt"></div>
      <List>
        <ListItem key={"Logout"} disablePadding>
          <Link to={"/logout"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 18}
              onClick={(event) => handleListItemClick(event, 18)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
      <div className="title-nav expt"></div>
    </div>
  );
};

export default DrawerNav;
