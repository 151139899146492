import { Auth } from "../../models/auth.model";
import isEmpty from "../../utils/isempty.utils";
import http from "../http-common";

const login = (data: Auth) => {
  return http.post<any>("/auth/login", data);
};

const logout = () => {
  sessionStorage.removeItem("token");
  //return http.post<any>(`/logout`,{headers:{"Authorization": 'Bearer ' + token}});
};

/*const authenticate = (token: string) => {
  return http.get("/actual", { headers: { Authorization: "Bearer " + token } });
};
const refershToken = (token: string) => {
  return http.get("/refresh", { headers: { Authorization: "Bearer " + token } });
};*/

const getUserConnected = () => {
  if (isEmpty(sessionStorage.getItem("token"))) {
    return "";
  } else {
    return sessionStorage.getItem("token");
  }
};

const AuthService = {
  login,
  logout,
  getUserConnected,
};

export default AuthService;
