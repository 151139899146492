import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Snackbar,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { ProfileModel } from "../../models/entities/profile/response/profile.response";
import { UserRoleEnum } from "../../models/enum/user-role.enum";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../utils/isempty.utils";
import { UserModel } from "../../models/entities/user/user.response.model";
import UserService from "../../services/api/user.service";
import { getMe } from "../../actions/API/user.action";
import AuthService from "../../services/api/auth.service";

export const AccountProfileDetails = () => {
  const dispatch: any = useDispatch();
  const user = useSelector((state: any) => state.userReducer);
  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState<any>("");

  const [values, setValues] = useState<any>({
    username: "Anika",
    mail: "Visser",
  });

  const handleChange = useCallback((event: { target: { name: any; value: any } }) => {
    setValues((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    requestModifyApi(values, token);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const requestModifyApi = (data: UserModel, token: string) => {
    UserService.update(user.id, data, token)
      .then((r) => {
        if (r.status === 204) {
          dispatch(getMe(AuthService.getUserConnected()));
          setTimeout(() => {
            setValues({
              username: user.username,
              mail: user.mail,
            });
            setOpenCreated(true);
            setMessage("Compte mise a jours");
            setTimeout(() => {
              setOpenCreated(false);
            }, 1000);
          }, 300);
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Compte non récuperer");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      setToken(AuthService.getUserConnected());
      setValues({
        username: user.username,
        mail: user.mail,
      });
    } else {
      setValues({
        username: "DH7789-dev",
        mail: "dharnaud77@hotmail.Fr",
      });
    }
  }, [user]);

  return (
    <>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card sx={{ borderRadius: 10, padding: 5 }}>
          <CardHeader
            subheader="Information du profile que vous pouvez editer"
            title="Compte"
            sx={{ marginBottom: "20px" }}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="User Name"
                    name="username"
                    onChange={handleChange}
                    required
                    value={values.username}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField fullWidth label="Email" name="mail" onChange={handleChange} required value={values.mail} />
                </Grid>
                <Grid xs={12} md={6}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "10px" }}>
                      Role Profile Attribué
                    </div>
                    {!isEmpty(user) &&
                      user.profiles.map((values: any, index: number) => (
                        <div key={index} style={{ fontSize: "12px", fontWeight: "400", marginBottom: "10px" }}>
                          {values.roleProfile}
                        </div>
                      ))}
                  </div>
                </Grid>
                <Grid xs={12} md={6}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "10px" }}>Roles Attribué</div>
                    {!isEmpty(user) &&
                      user.roles.map((values: any, index: number) => (
                        <div key={index} style={{ fontSize: "12px", fontWeight: "400", marginBottom: "10px" }}>
                          {values}
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end", marginTop: "10px" }}>
            <Button variant="contained" onClick={handleSubmit} sx={{ background: "rgb(34, 10, 110)" }}>
              Sauvegarder
            </Button>
          </CardActions>
        </Card>
      </form>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
