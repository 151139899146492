import { Box, Card, Paper, Typography, CardHeader, CardContent, SxProps } from "@mui/material";
import { WidgetHealthCheckInterface } from "../../models/component/health-check/widget-health-check.model";
import React, { ReactElement, useEffect } from "react";
import ApiIcon from "@mui/icons-material/Api";
import CachedIcon from "@mui/icons-material/Cached";

const WidgetHealthCheck = (props: WidgetHealthCheckInterface) => {
  const { title, list, reloadF, isDashboard } = props;

  return (
    <Card
      sx={
        isDashboard === false
          ? {
              width: "calc(33% - 30px)",
              "&:nth-child(3n + 3)": { marginRight: "10px" },
              marginRight: "20px",
              marginBottom: "20px",
            }
          : { width: "25%" }
      }
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px" }}>
        <CardHeader title={title} sx={{ marginBottom: "-20px" }} />
        {isDashboard === false && (
          <CachedIcon onClick={() => reloadF()} sx={{ position: "relative", top: "10px", cursor: "pointer" }} />
        )}
      </div>
      <CardContent>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Paper variant="outlined" sx={{ py: 2.5, textAlign: "center" }}>
            {list.heathCheck.info.database.status === "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"success"} />{" "}
              </Box>
            )}
            {list.heathCheck.info.database.status != "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"error"} />{" "}
              </Box>
            )}
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Database
            </Typography>
          </Paper>
          <Paper variant="outlined" sx={{ py: 2.5, textAlign: "center" }}>
            {list.heathCheck.info.memoryHeap.status === "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"success"} />{" "}
              </Box>
            )}
            {list.heathCheck.info.memoryHeap.status != "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"error"} />{" "}
              </Box>
            )}
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Memory Heap
            </Typography>
          </Paper>
          <Paper variant="outlined" sx={{ py: 2.5, textAlign: "center" }}>
            {list.heathCheck.info.memoryRSS.status === "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"success"} />{" "}
              </Box>
            )}
            {list.heathCheck.info.memoryRSS.status != "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"error"} />{" "}
              </Box>
            )}
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Memory RSS
            </Typography>
          </Paper>

          <Paper variant="outlined" sx={{ py: 2.5, textAlign: "center" }}>
            {list.heathCheck.info.diskHealth.status === "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"success"} />{" "}
              </Box>
            )}
            {list.heathCheck.info.diskHealth.status != "up" && (
              <Box sx={{ mb: 0.5 }}>
                {" "}
                <ApiIcon color={"error"} />{" "}
              </Box>
            )}
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Memory Health
            </Typography>
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};

export default WidgetHealthCheck;
