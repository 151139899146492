import http from "../http-common";
import { MailDto } from "../../models/entities/mail/mail.dto";
import { GenerateUserDto } from "../../models/entities/user/dto/generate-user.dto";

const sendMail = (data: MailDto) => {
  return http.post<any>("/api-mail/", data);
};

const getMailWaiting = (token: string) => {
  return http.get<any>("/api-landing-page/get-all", { headers: { Authorization: "Bearer " + token } });
};

const generateUserFromMail = (data: GenerateUserDto, token: string) => {
  return http.post<any>("/user/admin/generate-user-from-mail", data, { headers: { Authorization: "Bearer " + token } });
};

const MailService = {
  sendMail,
  getMailWaiting,
  generateUserFromMail,
};

export default MailService;
