import { Avatar } from "@mui/material";
import MuiUserUtils from "../../utils/mui.user.utils";
import { useEffect, useState } from "react";
import { doc } from "prettier";

import AuthService from "../../services/api/auth.service";
import { getMe } from "../../actions/API/user.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isEmpty from "../../utils/isempty.utils";

const CardUserMinimal = () => {
  const dispatch: any = useDispatch();
  const user = useSelector((state: any) => state.userReducer);
  const navigate = useNavigate();
  const [name, setName] = useState<string>("test gggg");

  useEffect(() => {
    if (AuthService.getUserConnected() != "") {
      dispatch(getMe(AuthService.getUserConnected()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      setName(user.username);
    } else {
      setName("Test test");
    }
  }, [user]);

  return (
    <div className="component-card-user-minimal">
      <Avatar
        {...MuiUserUtils.stringAvatar(name)}
        sx={{ backgroundColor: "rgba(34, 10, 110,1)", borderRadius: "50%" }}
      />
      <div className="user-information">
        <div className="title">{user.username}</div>
        <div className="subtitle">{user.mail}</div>
      </div>
    </div>
  );
};

export default CardUserMinimal;
