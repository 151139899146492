import React, { ChangeEvent, useEffect, useState } from "react";
import { SubscriptionResponse } from "../../../models/entities/finance/subscription/response/subscription.response";
import { StatusSubscriptionEnum } from "../../../models/enum/status-subscription.enum";
import { applySortFilterOther, getComparator } from "../../../utils/array-user-list";
import isEmpty from "../../../utils/isempty.utils";
import AuthService from "../../../services/api/auth.service";
import SubscriptionService from "../../../services/api/finance/subscription.service";
import {
  Alert,
  Card,
  Checkbox,
  Container,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrayListToolbar from "../../../components/array_manager/ArrayListToolbar";
import Scrollbar from "../../../components/mui/scrollbar/Scrollbar";
import ArrayListHead from "../../../components/array_manager/ArrayListHead";
import DeleteIcon from "@mui/icons-material/Delete";

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "currentPeriodStart", label: "CurrentPeriodStart", alignRight: false },
  { id: "currentPeriodEnd", label: "CurrentPeriodEnd", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "trialStartDate", label: "TrialStartDate", alignRight: false },
  { id: "trialEndDate", label: "TrialEndDate", alignRight: false },
  { id: "subscriptionStripeId", label: "SubscriptionStripeId", alignRight: false },
  { id: "stripeLatestInvoiceId", label: "StripeLatestInvoiceId", alignRight: false },
  { id: "profileOwnerId", label: "ProfileOwnerId", alignRight: false },
  { id: "profileEmployeeIds", label: "ProfileEmployeeIds", alignRight: false },
  { id: "canceledAt", label: "CanceledAt", alignRight: false },
  { id: "cancelAtPeriodEnd", label: "CancelAtPeriodEnd", alignRight: false },
  { id: "createdAt", label: "CreatedAt", alignRight: false },
  { id: "" },
];

const MockSubscription: Array<SubscriptionResponse> = [
  {
    id: "",
    currentPeriodStart: "",
    currentPeriodEnd: "",
    status: StatusSubscriptionEnum.ACTIVE,
    trialStartDate: "",
    trialEndDate: "",
    subscriptionStripeId: "",
    stripeLatestInvoiceId: "",
    profileOwnerId: "",
    profileEmployeeIds: [],
    canceledAt: "",
    cancelAtPeriodEnd: false,
    price: {
      id: "",
      price: 0,
      currency: "",
      stripePriceId: "",
      interval: "",
      type: "",
      active: false,
      unitAmount: 0,
      unitAmountDecimal: 0,
      trialPeriodDays: 0,
      productId: "",
      intervalCount: 0,
      priceId: "",
      usageType: "",
      productStripeId: "",
      createdAt: "",
      updatedAt: "",
      priceStripeId: "",
    },
    user: {
      id: "",
      username: "",
      mail: "",
      profiles: [],
      roles: [],
      createdAt: "",
      stripeCustomerId: "",
    },
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  },
];

const subscription: SubscriptionResponse = {
  id: "",
  currentPeriodStart: "",
  currentPeriodEnd: "",
  status: StatusSubscriptionEnum.ACTIVE,
  trialStartDate: "",
  trialEndDate: "",
  subscriptionStripeId: "",
  stripeLatestInvoiceId: "",
  profileOwnerId: "",
  profileEmployeeIds: [],
  canceledAt: "",
  cancelAtPeriodEnd: false,
  price: {
    id: "",
    price: 0,
    currency: "",
    stripePriceId: "",
    interval: "",
    type: "",
    active: false,
    unitAmount: 0,
    unitAmountDecimal: 0,
    trialPeriodDays: 0,
    productId: "",
    intervalCount: 0,
    priceId: "",
    usageType: "",
    productStripeId: "",
    createdAt: "",
    updatedAt: "",
    priceStripeId: "",
  },
  user: {
    id: "",
    username: "",
    mail: "",
    profiles: [],
    roles: [],
    createdAt: "",
    stripeCustomerId: "",
  },
  createdAt: "",
  updatedAt: "",
  deletedAt: "",
};
const Subscription = () => {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<any>("asc");

  const [selected, setSelected] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<any>("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [subscriptions, setSubscriptions] = useState<Array<SubscriptionResponse>>(MockSubscription);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subscriptions.length) : 0;

  const filteredSubscription = applySortFilterOther(subscriptions, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredSubscription.length && !!filterName;

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState<any>("");
  const [subscriptionChangeForUpdateOrSup, setSubscriptionChangeForUpdateOrSup] = useState(subscription);
  const handleOpenMenu = (subscription: SubscriptionResponse) => {
    setSubscriptionChangeForUpdateOrSup(subscription);
  };

  const handleRequestSort = (event: ChangeEvent<HTMLInputElement>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = subscriptions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const requestDeleteApi = (subscription: SubscriptionResponse, token: string) => {
    SubscriptionService.cancelSubscriptionAdmin(
      subscription.subscriptionStripeId,
      subscription.user.stripeCustomerId,
      token,
    )
      .then((r) => {
        if (r.status === 200) {
          SubscriptionService.getAllSubscriptions(token)
            .then((r) => {
              if (r.status === 200) {
                setSubscriptions(r.data);
                setOpenCreated(true);
                setMessage("Subscription " + subscription.subscriptionStripeId + " cancel");
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("Subscription non récuperer");
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Impossible de cancel l'Subscription");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      SubscriptionService.getAllSubscriptions(token)
        .then((r) => {
          if (r.status === 200) {
            setSubscriptions(r.data);
          }
        })
        .catch((reason) => console.log(""));
    }
  }, [token]);

  return (
    <>
      <div className="view-array">
        <Container sx={{ maxWidth: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manager Subscription
            </Typography>
            <></>
          </Stack>

          <Card>
            <ArrayListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar sx={{ height: 400 }}>
              <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 400 }}>
                <Table sx={{ height: 400 }}>
                  <ArrayListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={subscriptions.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredSubscription
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index: number) => {
                        const {
                          id,
                          currentPeriodStart,
                          currentPeriodEnd,
                          status,
                          trialStartDate,
                          trialEndDate,
                          subscriptionStripeId,
                          stripeLatestInvoiceId,
                          profileOwnerId,
                          profileEmployeeIds,
                          canceledAt,
                          cancelAtPeriodEnd,
                          createdAt,
                        } = row;
                        const selectedUser = selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            </TableCell>

                            <TableCell align="left">{id}</TableCell>

                            <TableCell align="left">{currentPeriodStart}</TableCell>
                            <TableCell align="left">{currentPeriodEnd}</TableCell>
                            <TableCell align="left">{status.toString()}</TableCell>
                            <TableCell align="left">{trialStartDate}</TableCell>
                            <TableCell align="left">{trialEndDate}</TableCell>
                            <TableCell align="left">{subscriptionStripeId}</TableCell>
                            <TableCell align="left">{stripeLatestInvoiceId}</TableCell>
                            <TableCell align="left">{profileOwnerId}</TableCell>
                            <TableCell align="left">{profileEmployeeIds}</TableCell>
                            <TableCell align="left">{canceledAt}</TableCell>
                            <TableCell align="left">{cancelAtPeriodEnd}</TableCell>
                            <TableCell align="left">{createdAt}</TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit">
                                <DeleteIcon onClick={() => requestDeleteApi(row, token)} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 35]}
              component="div"
              count={subscriptions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Subscription;
