import ErrorPage from "./views/ErrorPage";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Login from "./views/login/Login";
import Dashboard from "./views/dashboard/Dashboard";
import Logout from "./views/Logout";
import Price from "./views/finance/price/Price";
import GlobalStructure from "./layouts/GlobalStructure";
import Cards from "./views/cards/Cards";
import Enterprise from "./views/enterprise/Enterprise";
import Profile from "./views/profile/Profile";
import Settings from "./views/settings/Settings";

import User from "./views/user/User";
import Log from "./views/log/Log";
import Mail from "./views/mail/Mail";
import HealthCheck from "./views/health-check/HealthCheck";
import Address from "./views/address/Address";

import Group from "./views/groupe/Group";
import Media from "./views/media/Media";
import Occupation from "./views/occupation/Occupation";
import ProfileG from "./views/profile-g/ProfileG";
import SocialNetwork from "./views/social-network/SocialNetwork";
import Product from "./views/finance/product/Product";
import Subscription from "./views/finance/subsciption/Subscription";
import LogMonitoring from "./views/log-monitoring/LogMonitoring";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <GlobalStructure>
              {" "}
              <Dashboard />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/price"
          element={
            <GlobalStructure>
              {" "}
              <Price />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/product"
          element={
            <GlobalStructure>
              {" "}
              <Product />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <GlobalStructure>
              {" "}
              <Subscription />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/cards"
          element={
            <GlobalStructure>
              {" "}
              <Cards />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/enterprise"
          element={
            <GlobalStructure>
              {" "}
              <Enterprise />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/users"
          element={
            <GlobalStructure>
              {" "}
              <User />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/profile"
          element={
            <GlobalStructure>
              {" "}
              <Profile />{" "}
            </GlobalStructure>
          }
        />
        {/*        <Route path="/settings" element={<GlobalStructure> <Settings/> </GlobalStructure>} />*/}
        <Route
          path="/log"
          element={
            <GlobalStructure>
              {" "}
              <LogMonitoring />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/mail"
          element={
            <GlobalStructure>
              {" "}
              <Mail />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/health-check"
          element={
            <GlobalStructure>
              {" "}
              <HealthCheck />{" "}
            </GlobalStructure>
          }
        />
        {/*        <Route path="/address" element={<GlobalStructure> <Address/> </GlobalStructure>} />*/}
        <Route
          path="/group"
          element={
            <GlobalStructure>
              {" "}
              <Group />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/media"
          element={
            <GlobalStructure>
              {" "}
              <Media />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/occupation"
          element={
            <GlobalStructure>
              {" "}
              <Occupation />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/profile-g"
          element={
            <GlobalStructure>
              {" "}
              <ProfileG />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/social-network"
          element={
            <GlobalStructure>
              {" "}
              <SocialNetwork />{" "}
            </GlobalStructure>
          }
        />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </Router>
  );
};

export default App;
