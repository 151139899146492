import Typography from "@mui/material/Typography";
import WidgetCardInformation from "../../components/widget_card_information/WidgetCardInformation";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WidgetHealthCheck from "../../components/widget_health-check/WidgetHealthCheck";
import { useEffect, useState } from "react";
import { HealthCheckModel } from "../../models/component/health-check/health-check.model";
import HealthCheckService from "../../services/api/health-check.service";
import StatsService from "../../services/api/stats.service";
import AuthService from "../../services/api/auth.service";
import CompanyService from "../../services/api/compagny.service";
import isEmpty from "../../utils/isempty.utils";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ReceiptIcon from "@mui/icons-material/Receipt";

export default function Dashboard() {
  const ObjectVide: HealthCheckModel = {
    heathCheck: {
      details: {
        database: { status: "not" },
        diskHealth: { status: "not" },
        memoryHeap: { status: "not" },
        memoryRSS: { status: "not" },
      },
      error: {
        database: { status: "not" },
        diskHealth: { status: "not" },
        memoryHeap: { status: "not" },
        memoryRSS: { status: "not" },
      },
      info: {
        database: { status: "not" },
        diskHealth: { status: "not" },
        memoryHeap: { status: "not" },
        memoryRSS: { status: "not" },
      },
      status: "not",
    },
  };
  const [healthCheckApiSharemee, setHealthCheckApiSharemee] = useState<HealthCheckModel>(ObjectVide);
  const [healthCheckApiLog, setHealthCheckApiLog] = useState<HealthCheckModel>(ObjectVide);
  const [healthCheckApiWaiting, setHealthCheckApiWaiting] = useState<HealthCheckModel>(ObjectVide);
  const [healthCheckApiMail, setHealthCheckApiMail] = useState<HealthCheckModel>(ObjectVide);
  const [token, setToken] = useState<any>("");
  const [numberOfSubscriptions, setNumberOfSubscriptions] = useState(0);
  const [numberOfCards, setNumberOfCards] = useState(0);
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfCompany, setNumberOfCompany] = useState(0);
  useEffect(() => {
    HealthCheckService.checkSharemee().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiSharemee(r.data);
      }
    });

    HealthCheckService.checkLog().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiLog(r.data);
      }
    });

    HealthCheckService.checkWaiting().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiWaiting(r.data);
      }
    });

    HealthCheckService.checkMail().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiMail(r.data);
      }
    });
  }, []);

  const handleRefresh = () => {
    return;
  };

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      StatsService.getCountAllCart(token)
        .then((r) => {
          if (r.status === 200) {
            setNumberOfCards(r.data);
          }
        })
        .catch((reason) => console.log(""));
      StatsService.getAllCompagnyCount(token)
        .then((r) => {
          if (r.status === 200) {
            setNumberOfCompany(r.data);
          }
        })
        .catch((reason) => console.log(""));

      StatsService.getCountAllSubscription(token)
        .then((r) => {
          if (r.status === 200) {
            setNumberOfSubscriptions(r.data);
          }
        })
        .catch((reason) => console.log(""));

      StatsService.getCountAllUser(token)
        .then((r) => {
          if (r.status === 200) {
            setNumberOfUsers(r.data);
          }
        })
        .catch((reason) => console.log(""));
    }
  }, [token]);

  return (
    <div className="view-dashboard">
      <div className="title-header">Hi, Welcome Back</div>
      <div className="container-dashboard">
        <WidgetCardInformation
          color={"#FFF"}
          icon={<ReceiptIcon />}
          title={"Nombre d'adherent"}
          total={numberOfSubscriptions}
          sx={{}}
          bgcolor={"#f04337"}
        />
        <WidgetCardInformation
          color={"#FFF"}
          icon={<CreditCardIcon />}
          title={"Cartes"}
          total={numberOfCards}
          sx={{}}
          bgcolor={"#11b981"}
        />
        <WidgetCardInformation
          color={"#FFF"}
          icon={<AccountCircleIcon />}
          title={"Utilisateur"}
          total={numberOfUsers}
          sx={{}}
          bgcolor={"#f79007"}
        />
        <WidgetCardInformation
          color={"#FFF"}
          icon={<ApartmentIcon />}
          title={"Compagny"}
          total={numberOfCompany}
          sx={{}}
          bgcolor={"#6366f1"}
        />
      </div>
      <div className="container-dashboard marginTop">
        <WidgetHealthCheck
          list={healthCheckApiSharemee}
          subheader={"Traffic Network"}
          title={"Api ShareMee"}
          isDashboard={true}
          reloadF={() => handleRefresh()}
        />
        <WidgetHealthCheck
          list={healthCheckApiLog}
          subheader={"Traffic Network"}
          title={"Api Log"}
          isDashboard={true}
          reloadF={() => handleRefresh()}
        />
        <WidgetHealthCheck
          list={healthCheckApiWaiting}
          subheader={"Traffic Network"}
          title={"Api Waiting"}
          isDashboard={true}
          reloadF={() => handleRefresh()}
        />
        <WidgetHealthCheck
          list={healthCheckApiMail}
          subheader={"Traffic Network"}
          title={"Api Mail"}
          isDashboard={true}
          reloadF={() => handleRefresh()}
        />
      </div>
    </div>
  );
}
