import { OccupationModel } from "../../../models/entities/occupation/response/occupation-response";
import { PriceResponse } from "../../../models/entities/finance/price/response/price.response";
import { useForm } from "react-hook-form";
import React, { ChangeEvent, useEffect, useState } from "react";
import { applySortFilterEnterprise, applySortFilterOther, getComparator } from "../../../utils/array-user-list";
import { CreateOccupationRequest } from "../../../models/entities/occupation/request/create-occupation.request";
import { UpdateOccupationRequest } from "../../../models/entities/occupation/request/update-occupation.request";
import OccupationService from "../../../services/api/occupation.service";
import isEmpty from "../../../utils/isempty.utils";
import AuthService from "../../../services/api/auth.service";
import PriceService from "../../../services/api/finance/price.service";
import { UpdatePriceStripeRequest } from "../../../models/entities/finance/stripe/request/update-price-stripe.request";
import { CreatePriceStripeRequest } from "../../../models/entities/finance/stripe/request/create-price-stripe.request";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ArrayListToolbar from "../../../components/array_manager/ArrayListToolbar";
import Scrollbar from "../../../components/mui/scrollbar/Scrollbar";
import ArrayListHead from "../../../components/array_manager/ArrayListHead";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "stripePriceId", label: "StripePriceId", alignRight: false },
  { id: "interval", label: "Interval", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "active", label: "Active", alignRight: false },
  { id: "unitAmount", label: "UnitAmount", alignRight: false },
  { id: "unitAmountDecimal", label: "UnitAmountDecimal", alignRight: false },
  { id: "createdAt", label: "CreatedAt", alignRight: false },
  { id: "updatedAt", label: "UpdatedAt", alignRight: false },
  { id: "" },
];

const MockPrice: Array<PriceResponse> = [
  {
    id: "",
    price: 0,
    currency: "",
    stripePriceId: "",
    interval: "",
    type: "",
    active: false,
    unitAmount: 0,
    unitAmountDecimal: 0,
    trialPeriodDays: 0,
    productId: "",
    intervalCount: 0,
    priceId: "",
    usageType: "",
    productStripeId: "",
    createdAt: "",
    updatedAt: "",
    priceStripeId: "",
  },
];

const price: PriceResponse = {
  id: "",
  price: 0,
  currency: "",
  stripePriceId: "",
  interval: "",
  type: "",
  active: false,
  unitAmount: 0,
  unitAmountDecimal: 0,
  trialPeriodDays: 0,
  productId: "",
  intervalCount: 0,
  priceId: "",
  usageType: "",
  productStripeId: "",
  createdAt: "",
  updatedAt: "",
  priceStripeId: "",
};
const Price = () => {
  const { register, handleSubmit, getValues, resetField } = useForm<PriceResponse>({ mode: "onChange" });

  const [open, setOpen] = useState<any>(null);
  const [openAnchoEl, setOpenAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<any>("asc");

  const [selected, setSelected] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<any>("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [prices, setPrices] = useState<Array<PriceResponse>>(MockPrice);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prices.length) : 0;

  const filteredPrice = applySortFilterOther(prices, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredPrice.length && !!filterName;

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [titleModal, setTitleModal] = useState("Nouvel Occupation");
  const [priceSelected, setPriceSelected] = useState<PriceResponse>(price);
  const [isButtonCreated, setIsButtonCreated] = useState(true);
  const [token, setToken] = useState<any>("");
  const [priceChangeForUpdateOrSup, setPriceChangeForUpdateOrSup] = useState(price);

  const handleOpenMenu = (price: PriceResponse) => {
    setOpen(true);
    setPriceChangeForUpdateOrSup(price);
  };

  const handleAnchorEl = (event: any) => {
    setOpenAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event: ChangeEvent<HTMLInputElement>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = prices.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const onSubmit = handleSubmit((data) => {
    const dataModelCreate: CreatePriceStripeRequest = {
      active: false,
      productId: data.productId,
      unitAmount: data.unitAmount,
      interval: data.interval,
      usageType: data.usageType,
      productStripeId: data.productStripeId,
      intervalCount: data.intervalCount,
      trialPeriodDays: data.trialPeriodDays,
    };
    const dataModelUpdate: UpdatePriceStripeRequest = {
      active: false,
      priceId: data.priceId,
      priceStripeId: data.priceStripeId,
      productId: data.productId,
    };
    if (isButtonCreated === true) {
      requestCreateApi(dataModelCreate, token);
    } else {
      requestModifyApi(dataModelUpdate, token);
    }
  });

  const handleOpenCreatePrice = () => {
    resetField("productId");
    resetField("priceStripeId");
    resetField("unitAmount");
    resetField("interval");
    resetField("usageType");
    resetField("active");
    resetField("productStripeId");
    resetField("trialPeriodDays");
    setIsButtonCreated(true);
    setTitleModal("Nouveaux Prix");
    handleOpen();
  };
  const handleOpenModifyPrice = (valuePrice: PriceResponse) => {
    resetField("productId");
    resetField("priceStripeId");
    resetField("unitAmount");
    resetField("interval");
    resetField("usageType");
    resetField("active");
    resetField("productStripeId");
    resetField("trialPeriodDays");
    setIsButtonCreated(false);
    setPriceSelected(valuePrice);
    setTitleModal("Modification du Prix");
    handleOpen();
  };

  const handleDeletePrice = () => {
    requestDeleteApi(token);
  };

  const requestCreateApi = (data: CreatePriceStripeRequest, token: string) => {
    PriceService.createPrice(data, token)
      .then((r) => {
        if (r.status === 201) {
          PriceService.getAllPrice(token)
            .then((r) => {
              if (r.status === 200) {
                setPrices(r.data);
                setOpenCreated(true);
                setMessage("Prix crée");
                setOpenModal(false);
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("Prix non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Prix non crée");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const requestDeleteApi = (token: string) => {
    PriceService.removePrice(priceChangeForUpdateOrSup.id, token)
      .then((r) => {
        if (r.status === 204) {
          PriceService.getAllPrice(token)
            .then((r) => {
              if (r.status === 200) {
                setPrices(r.data);
                setOpenCreated(true);
                setMessage("Prix " + priceChangeForUpdateOrSup.id + " supprimé");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("Prix non récuperer");
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Impossible de supprimé l'Prix");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const requestModifyApi = (data: UpdatePriceStripeRequest, token: string) => {
    PriceService.updatePrice(data, token)
      .then((r) => {
        if (r.status === 200) {
          PriceService.getAllPrice(token)
            .then((r) => {
              if (r.status === 200) {
                setPrices(r.data);
                setOpenCreated(true);
                setMessage("Prix mise a jours");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("Prix non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Prix non récuperer");
        setOpenModal(false);
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      PriceService.getAllPrice(token)
        .then((r) => {
          if (r.status === 200) {
            setPrices(r.data);
          }
        })
        .catch((reason) => console.log(""));
    }
  }, [token]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            border: "2px solid transparent",
          }}
        >
          <div className="container-modal">
            <div className="header-modal">
              <div className="title-header">{titleModal}</div>
              <img
                className="button-close"
                onClick={handleClose}
                src="/assets/logo/close_simple.svg"
                alt="close button"
              />
            </div>
            <form onSubmit={onSubmit} className="form-container">
              <div className="container-input-modal">
                <div className="title-input">
                  productId <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={priceSelected.productId}
                  placeholder="productId"
                  {...register("productId")}
                />
              </div>
              {isButtonCreated === true && (
                <>
                  <div className="container-input-modal">
                    <div className="title-input">
                      unitAmount <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="number"
                      defaultValue={priceSelected.unitAmount}
                      placeholder="name"
                      {...register("unitAmount")}
                    />
                  </div>
                  <div className="container-input-modal">
                    <div className="title-input">
                      interval <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="text"
                      defaultValue={priceSelected.interval}
                      placeholder="interval"
                      {...register("interval")}
                    />
                  </div>
                  <div className="container-input-modal">
                    <div className="title-input">
                      usageType <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="text"
                      defaultValue={priceSelected.usageType}
                      placeholder="usageType"
                      {...register("usageType")}
                    />
                  </div>
                  <div className="container-input-modal">
                    <div className="title-input">
                      productStripeId <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="text"
                      defaultValue={priceSelected.productStripeId}
                      placeholder="productStripeId"
                      {...register("productStripeId")}
                    />
                  </div>
                  <div className="container-input-modal">
                    <div className="title-input">
                      trialPeriodDays <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="text"
                      defaultValue={priceSelected.trialPeriodDays}
                      placeholder="trialPeriodDays"
                      {...register("trialPeriodDays")}
                    />
                  </div>
                  <div className="container-input-modal">
                    <div className="title-input">
                      intervalCount <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="number"
                      defaultValue={priceSelected.intervalCount}
                      placeholder="intervalCount"
                      {...register("intervalCount")}
                    />
                  </div>
                </>
              )}
              {isButtonCreated === false && (
                <>
                  <div className="container-input-modal">
                    <div className="title-input">
                      priceStripeId <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="text"
                      defaultValue={priceSelected.priceStripeId}
                      placeholder="priceStripeId"
                      {...register("priceStripeId")}
                    />
                  </div>
                  <div className="container-input-modal">
                    <div className="title-input">
                      priceId <span>*</span>
                    </div>
                    <input
                      className="input-container-modal"
                      type="text"
                      defaultValue={priceSelected.priceId}
                      placeholder="priceId"
                      {...register("priceId")}
                    />
                  </div>
                </>
              )}

              {isButtonCreated === true && <button className="button-upload">Créer </button>}
              {isButtonCreated === false && <button className="button-upload">Modifier</button>}
            </form>
          </div>
        </div>
      </Modal>
      <div className="view-array">
        <Container sx={{ maxWidth: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manager Price
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleOpenCreatePrice()}
              sx={{ background: "rgb(34, 10, 110)" }}
              startIcon={<AddIcon />}
            >
              New Price
            </Button>
          </Stack>

          <Card>
            <ArrayListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar sx={{ height: 400 }}>
              <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 400 }}>
                <Table sx={{ height: 400 }}>
                  <ArrayListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={prices.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredPrice
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index: number) => {
                        const {
                          id,
                          price,
                          currency,
                          stripePriceId,
                          interval,
                          type,
                          active,
                          unitAmount,
                          unitAmountDecimal,
                          createdAt,
                          updatedAt,
                        } = row;
                        const selectedUser = selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            </TableCell>

                            <TableCell align="left">{id}</TableCell>

                            <TableCell align="left">{price}</TableCell>
                            <TableCell align="left">{currency}</TableCell>
                            <TableCell align="left">{stripePriceId}</TableCell>
                            <TableCell align="left">{interval}</TableCell>
                            <TableCell align="left">{type}</TableCell>
                            <TableCell align="left">{unitAmount}</TableCell>
                            <TableCell align="left">{unitAmountDecimal}</TableCell>

                            <TableCell align="left">{createdAt.toString()}</TableCell>

                            <TableCell align="left">{updatedAt.toString()}</TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={handleAnchorEl}>
                                <MoreVertIcon onClick={() => handleOpenMenu(row)} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 35]}
              component="div"
              count={prices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <Popover
          open={Boolean(open)}
          anchorEl={openAnchoEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem sx={{ color: "warning.main" }} onClick={() => handleOpenModifyPrice(priceChangeForUpdateOrSup)}>
            <ChangeCircleIcon sx={{ mr: 2 }} />
            update
          </MenuItem>
          <MenuItem sx={{ color: "error.main" }} onClick={() => handleDeletePrice()}>
            <DeleteIcon sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Price;
