import httpSharemme from "../http-common";
import httpMail from "../http-common-mail";
import httpLog from "../http-common-log";
import httpWaiting from "../http-common-waiting";
import { HealthCheckModel } from "../../models/component/health-check/health-check.model";

const checkSharemee = () => {
  return httpSharemme.get<HealthCheckModel>("/health-check/");
};

const checkLog = () => {
  return httpLog.get<HealthCheckModel>("/health-check/");
};

const checkWaiting = () => {
  return httpWaiting.get<HealthCheckModel>("/health-check/");
};

const checkMail = () => {
  return httpMail.get<HealthCheckModel>("/health-check/");
};

const HealthCheckService = {
  checkSharemee,
  checkLog,
  checkWaiting,
  checkMail,
};

export default HealthCheckService;
