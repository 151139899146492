import httpCommonLog from "../http-common-log";
import { GetLogsFilterDto } from "../../models/entities/log/dto/get-logs-filter.dto";
import { ResponseLogDto } from "../../models/entities/log/response/response-log.dto";

const getLog = (data: GetLogsFilterDto, token: string) => {
  return httpCommonLog.post<ResponseLogDto[]>("/getLogsAdmin", data, { headers: { Authorization: "Bearer " + token } });
};

const LogService = {
  getLog,
};

export default LogService;
