import React, { useEffect, useState } from "react";
import WidgetHealthCheck from "../../components/widget_health-check/WidgetHealthCheck";
import HealthCheckService from "../../services/api/health-check.service";
import { HealthCheckModel } from "../../models/component/health-check/health-check.model";

const HealthCheck = () => {
  const ObjectVide: HealthCheckModel = {
    heathCheck: {
      details: {
        database: { status: "not" },
        diskHealth: { status: "not" },
        memoryHeap: { status: "not" },
        memoryRSS: { status: "not" },
      },
      error: {
        database: { status: "not" },
        diskHealth: { status: "not" },
        memoryHeap: { status: "not" },
        memoryRSS: { status: "not" },
      },
      info: {
        database: { status: "not" },
        diskHealth: { status: "not" },
        memoryHeap: { status: "not" },
        memoryRSS: { status: "not" },
      },
      status: "not",
    },
  };
  const [healthCheckApiSharemee, setHealthCheckApiSharemee] = useState<HealthCheckModel>(ObjectVide);
  const [healthCheckApiLog, setHealthCheckApiLog] = useState<HealthCheckModel>(ObjectVide);
  const [healthCheckApiWaiting, setHealthCheckApiWaiting] = useState<HealthCheckModel>(ObjectVide);
  const [healthCheckApiMail, setHealthCheckApiMail] = useState<HealthCheckModel>(ObjectVide);

  const handleRefresh = (number: number) => {
    const checkApi = (api: () => Promise<any>, setData: (data: any) => void) => {
      api().then((r: any) => {
        if (r.status === 200) {
          setData(r.data);
        }
      });
    };

    switch (number) {
      case 1:
        checkApi(HealthCheckService.checkSharemee, setHealthCheckApiSharemee);
        break;
      case 2:
        checkApi(HealthCheckService.checkLog, setHealthCheckApiLog);
        break;
      case 3:
        checkApi(HealthCheckService.checkWaiting, setHealthCheckApiWaiting);
        break;
      default:
        checkApi(HealthCheckService.checkMail, setHealthCheckApiMail);
        break;
    }
  };

  useEffect(() => {
    HealthCheckService.checkSharemee().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiSharemee(r.data);
      }
    });

    HealthCheckService.checkLog().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiLog(r.data);
      }
    });

    HealthCheckService.checkWaiting().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiWaiting(r.data);
      }
    });

    HealthCheckService.checkMail().then((r) => {
      if (r.status === 200) {
        setHealthCheckApiMail(r.data);
      }
    });
  }, []);
  return (
    <div className="view--health-check">
      <div className="title-header">Check de la santé des Apps</div>
      <div className="container-health-check">
        <WidgetHealthCheck
          list={healthCheckApiSharemee}
          subheader={"Traffic Network"}
          title={"Api ShareMee"}
          reloadF={() => handleRefresh(1)}
          isDashboard={false}
        />
        <WidgetHealthCheck
          list={healthCheckApiLog}
          subheader={"Traffic Network"}
          title={"Api Log"}
          reloadF={() => handleRefresh(2)}
          isDashboard={false}
        />
        <WidgetHealthCheck
          list={healthCheckApiWaiting}
          subheader={"Traffic Network"}
          title={"Api Waiting"}
          reloadF={() => handleRefresh(3)}
          isDashboard={false}
        />
        <WidgetHealthCheck
          list={healthCheckApiMail}
          subheader={"Traffic Network"}
          title={"Api Mail"}
          reloadF={() => handleRefresh(4)}
          isDashboard={false}
        />
      </div>
    </div>
  );
};

export default HealthCheck;
