export enum LogModuleEnum {
  address = "address",
  apiLandingPage = "api-landing-page",
  apiMail = "api-mail",
  card = "card",
  company = "company",
  conversation = "conversation",
  group = "group",
  media = "media",
  notification = "notification",
  occupation = "occupation",
  payment = "payment",
  invoices = "invoices",
  price = "price",
  product = "product",
  stripe = "stripe",
  stripeWebhook = "stripe-webhook",
  subscription = "subscription",
  profile = "profile",
  socialNetwork = "social-network",
  user = "user",
}
