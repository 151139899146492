import http from "../http-common";
import { GetGroupWithCriteriaRequest } from "../../models/entities/groupe/request/get-group-with-criteria.request";
import { RemoveCardFromGroupRequest } from "../../models/entities/groupe/request/remove-card-from-group.request";
import { CreateGroupRequest } from "../../models/entities/groupe/request/create-group.request";
import { GiveAdminRightGroupRequest } from "../../models/entities/groupe/request/give-admin-right-group.request";
import { RemoveAdminRightGroupRequest } from "../../models/entities/groupe/request/remove-admin-right-group.request";
import { UpdateGroupRequest } from "../../models/entities/groupe/request/update-group.request";
import { AddCardToGroupRequest } from "../../models/entities/groupe/request/add-card-to-group.request";

const findAll = (token: string) => {
  return http.get<any>("/group/admin/find-all", { headers: { Authorization: "Bearer " + token } });
};

const findOne = (id: string, token: string) => {
  return http.get<any>(`/group/admin/${id}`, { headers: { Authorization: "Bearer " + token } });
};
const getMyGroup = (id: string, token: string) => {
  return http.get<any>(`/group/public/get-my-group/${id}`, { headers: { Authorization: "Bearer " + token } });
};
const getMyGroups = (token: string) => {
  return http.get<any>("/group/public/get-my-groups", { headers: { Authorization: "Bearer " + token } });
};

const getGroupWithUserId = (userId: string, token: string) => {
  return http.get<any>(`/group/admin/get-group-with-user-id/${userId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getGroupMemberWithGroupId = (groupId: string, token: string) => {
  return http.get<any>(`/group/admin/get-group-member-with-group-id/${groupId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getGroupMemberWithGroupIdPublic = (groupId: string, token: string) => {
  return http.get<any>(`/group/public/get-group-member-with-group-id/${groupId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getGroupMemberWithCardIdPublic = (cardId: string, token: string) => {
  return http.get<any>(`/group/public/get-group-member-with-card-id/${cardId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getGroupWithCriteria = (data: GetGroupWithCriteriaRequest, token: string) => {
  return http.post<any>("/group/admin/get-group-with-criteria", data, {
    headers: { Authorization: "Bearer " + token },
  });
};
const getGroupWhereCardIdIsAdmin = (cardId: string, token: string) => {
  return http.get<any>(`/group/public/get-group-where-card-id-is-admin/${cardId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getGroupWhereCardIdIsAdminAdmin = (cardId: string, token: string) => {
  return http.get<any>(`/group/admin/get-group-where-card-id-is-admin/${cardId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const leaveGroupPublic = (id: string, data: RemoveCardFromGroupRequest, token: string) => {
  return http.put<any>("/group/public/leave-group", data, { headers: { Authorization: "Bearer " + token } });
};

const createGroupPublic = (data: CreateGroupRequest, token: string) => {
  return http.post<any>("/group/public/create-group", data, { headers: { Authorization: "Bearer " + token } });
};

const createGroupAdmin = (data: CreateGroupRequest, token: string) => {
  return http.post<any>("/group/admin/create-group", data, { headers: { Authorization: "Bearer " + token } });
};

const deleteGroupPublic = (groupId: string, token: string) => {
  return http.delete<any>(`/group/public/delete-group/${groupId}`, { headers: { Authorization: "Bearer " + token } });
};

const deleteGroupAdmin = (groupId: string, token: string) => {
  return http.delete<any>(`/group/admin/delete-group/${groupId}`, { headers: { Authorization: "Bearer " + token } });
};

const giveAdminRightsPublic = (data: GiveAdminRightGroupRequest, token: string) => {
  return http.put<any>("/group/public/give-admin-rights", data, { headers: { Authorization: "Bearer " + token } });
};

const removeAdminRightsPublic = (data: RemoveAdminRightGroupRequest, token: string) => {
  return http.put<any>("/group/public/remove-admin-rights", data, { headers: { Authorization: "Bearer " + token } });
};

const giveAdminRightsAdmin = (data: GiveAdminRightGroupRequest, token: string) => {
  return http.put<any>("/group/public/give-admin-rights", data, { headers: { Authorization: "Bearer " + token } });
};

const removeAdminRightsAdmin = (data: RemoveAdminRightGroupRequest, token: string) => {
  return http.put<any>("/group/public/remove-admin-rights", data, { headers: { Authorization: "Bearer " + token } });
};

const removeCardFromGroupPublic = (data: RemoveCardFromGroupRequest, token: string) => {
  return http.put<any>("/group/public/remove-card-from-group", data, { headers: { Authorization: "Bearer " + token } });
};

const removeCardFromGroupAdmin = (data: RemoveCardFromGroupRequest, token: string) => {
  return http.put<any>("/group/admin/remove-card-from-group", data, { headers: { Authorization: "Bearer " + token } });
};

const restoreGroupAdmin = (groupId: string, data: RemoveCardFromGroupRequest, token: string) => {
  return http.put<any>(`/group/admin/restore-group/${groupId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const softDeleteGroupAdmin = (groupId: string, token: string) => {
  return http.delete<any>(`/group/soft-delete-group/${groupId}`, { headers: { Authorization: "Bearer " + token } });
};

const updateGroupAdmin = (groupId: string, data: UpdateGroupRequest, token: string) => {
  return http.put<any>(`/group/admin/update-group/${groupId}`, data, { headers: { Authorization: "Bearer " + token } });
};

const updateGroupPublic = (groupId: string, data: UpdateGroupRequest, token: string) => {
  return http.put<any>(`/group/public/update-group/${groupId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const deleteGroupMembershipAdmin = (groupId: string, token: string) => {
  return http.delete<any>(`/group/admin/delete-group-membership/${groupId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
const addCardToGroup = (data: AddCardToGroupRequest, token: string) => {
  return http.put<any>("/group/admin/add-card-to-group", data, { headers: { Authorization: "Bearer " + token } });
};

const addCardToMyGroup = (data: AddCardToGroupRequest, token: string) => {
  return http.put<any>("/group/public/add-card-to-group", data, { headers: { Authorization: "Bearer " + token } });
};

const GroupService = {
  findAll,
  findOne,
  getMyGroup,
  getMyGroups,
  getGroupWithUserId,
  getGroupMemberWithGroupId,
  getGroupMemberWithGroupIdPublic,
  getGroupMemberWithCardIdPublic,
  getGroupWithCriteria,
  getGroupWhereCardIdIsAdmin,
  getGroupWhereCardIdIsAdminAdmin,
  leaveGroupPublic,
  createGroupPublic,
  createGroupAdmin,
  deleteGroupPublic,
  deleteGroupAdmin,
  giveAdminRightsPublic,
  removeAdminRightsPublic,
  giveAdminRightsAdmin,
  removeAdminRightsAdmin,
  removeCardFromGroupPublic,
  removeCardFromGroupAdmin,
  restoreGroupAdmin,
  softDeleteGroupAdmin,
  updateGroupAdmin,
  updateGroupPublic,
  deleteGroupMembershipAdmin,
  addCardToGroup,
  addCardToMyGroup,
};

export default GroupService;
