import { MouseEventHandler, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import MuiStyleAppBarGlobal from "../../sass/mui/mui.appbarglobal";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./notification-popover/NotificationsPopover";

interface AppBarGlobal {
  handleDrawerToggle: MouseEventHandler<HTMLButtonElement> | undefined;
}
const AppBarGlobal = (props: AppBarGlobal) => {
  const { handleDrawerToggle } = props;
  const drawerWidth = 240;
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        background: "#FFF",
        boxShadow: "none",
        padding: "5px",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" }, color: "rgb(34, 10, 110)", backgroundColor: "rgba(99, 115, 129,0.15)" }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ position: "relative", left: "95%" }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarGlobal;
