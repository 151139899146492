import http from "../http-common";
import { CreateSocialNetworkModel } from "../../models/entities/social-network/request/create-social-network.request";
import { GetSocialNetworkWithCriteriaModel } from "../../models/entities/social-network/request/get-social-network-with-criteria.request";
import { UpdateSocialNetworkModel } from "../../models/entities/social-network/request/update-social-network.request";

const getAllSocialNetworks = (token: string) => {
  return http.get<any>("/social-network/public/", { headers: { Authorization: "Bearer " + token } });
};

const getSocialNetworkById = (id: string, token: string) => {
  return http.get<any>(`/social-network/public/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const getSocialNetworksWithCriteria = (data: GetSocialNetworkWithCriteriaModel, token: string) => {
  return http.post<any>("/social-network/admin/get-with-criteria", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const createSocialNetwork = (data: CreateSocialNetworkModel, token: string) => {
  return http.post<any>("/social-network/admin/create", data, { headers: { Authorization: "Bearer " + token } });
};

const updateSocialNetwork = (id: string, data: UpdateSocialNetworkModel, token: string) => {
  return http.put<any>(`/social-network/admin/update/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};

const deleteSocialNetwork = (id: string, token: string) => {
  return http.delete<any>(`/social-network/admin/delete/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const restoreSocialNetwork = (id: string, token: string) => {
  return http.delete<any>(`/social-network/admin/restore/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const SocialNetworkService = {
  getAllSocialNetworks,
  getSocialNetworkById,
  getSocialNetworksWithCriteria,
  createSocialNetwork,
  updateSocialNetwork,
  deleteSocialNetwork,
  restoreSocialNetwork,
};

export default SocialNetworkService;
