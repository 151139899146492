import http from "../../http-common";
import { CreateProductRequest } from "../../../models/entities/finance/product/request/create-product.request";
import { UpdateProductRequest } from "../../../models/entities/finance/product/request/update-product.request";

const getListProduct = (token: string) => {
  return http.get<any>("/product/public/list-product", { headers: { Authorization: "Bearer " + token } });
};
const getListProductAdmin = (token: string) => {
  return http.get<any>("/product/admin/list-product-admin", { headers: { Authorization: "Bearer " + token } });
};

const getProduct = (productId: string, token: string) => {
  return http.get<any>(`/product/public/get-product/${productId}`, { headers: { Authorization: "Bearer " + token } });
};

const getProductAdmin = (productId: string, token: string) => {
  return http.get<any>(`/product/admin/get-product-admin/${productId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const createProduct = (data: CreateProductRequest, token: string) => {
  return http.post<any>("/product/admin/create-product", data, { headers: { Authorization: "Bearer " + token } });
};

const updateProduct = (data: UpdateProductRequest, token: string) => {
  return http.put<any>("/product/admin/update-product", data, { headers: { Authorization: "Bearer " + token } });
};

const restoreProduct = (id: string, token: string) => {
  return http.put<any>(`/product/admin/restore-product//${id}`, { headers: { Authorization: "Bearer " + token } });
};

const deleteProduct = (id: string, token: string) => {
  return http.delete<any>(`/product/admin/remove-product/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const softDeleteProduct = (id: string, token: string) => {
  return http.delete<any>(`/product/admin/soft-remove-product/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const ProductService = {
  getListProduct,
  getProduct,
  getListProductAdmin,
  getProductAdmin,
  createProduct,
  updateProduct,
  restoreProduct,
  deleteProduct,
  softDeleteProduct,
};

export default ProductService;
