import http from "../../http-common";
import { CreatePriceStripeRequest } from "../../../models/entities/finance/stripe/request/create-price-stripe.request";
import { UpdatePriceStripeRequest } from "../../../models/entities/finance/stripe/request/update-price-stripe.request";

const getPriceById = (id: string, token: string) => {
  return http.get<any>(`/price/public/get-price-by-id/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const getAllPrice = (token: string) => {
  return http.get<any>("/price/admin/get-all-price", { headers: { Authorization: "Bearer " + token } });
};

const getAllPriceByProductId = (productId: string, token: string) => {
  return http.get<any>(`/price/public/get-all-price-by-product-id/${productId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const createPrice = (data: CreatePriceStripeRequest, token: string) => {
  return http.post<any>("/price/admin/create-price", data, { headers: { Authorization: "Bearer " + token } });
};

const updatePrice = (data: UpdatePriceStripeRequest, token: string) => {
  return http.put<any>("/price/admin/update-price", data, { headers: { Authorization: "Bearer " + token } });
};

const restorePrice = (id: string, token: string) => {
  return http.put<any>(`/price/admin/restore-price/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const removePrice = (id: string, token: string) => {
  return http.delete<any>(`/price/admin/remove-price/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const softRemovePrice = (id: string, token: string) => {
  return http.delete<any>(`/price/admin/soft-remove-price/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const PriceService = {
  getPriceById,
  getAllPrice,
  getAllPriceByProductId,
  createPrice,
  updatePrice,
  restorePrice,
  removePrice,
  softRemovePrice,
};

export default PriceService;
