import http from "../http-common";
import { GetOccupationWithCriteriaRequest } from "../../models/entities/occupation/request/get-occupation-with-criteria.request";
import { CreateOccupationRequest } from "../../models/entities/occupation/request/create-occupation.request";

const getAllOccupations = (token: string) => {
  return http.get<any>("/occupation/public/", { headers: { Authorization: "Bearer " + token } });
};

const getOccupationById = (id: string, token: string) => {
  return http.get<any>(`/occupation/public/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const getOccupationsWithCriteria = (data: GetOccupationWithCriteriaRequest, token: string) => {
  return http.post<any>("/occupation/admin/get-with-criteria", data, { headers: { Authorization: "Bearer " + token } });
};

const createOccupation = (data: CreateOccupationRequest, token: string) => {
  return http.post<any>("/occupation/admin/create", data, { headers: { Authorization: "Bearer " + token } });
};

const updateOccupation = (id: string, data: CreateOccupationRequest, token: string) => {
  return http.put<any>(`/occupation/admin/update/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};

const deleteOccupation = (id: string, token: string) => {
  return http.delete<any>(`/occupation/admin/delete/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const softDeleteOccupation = (id: string, token: string) => {
  return http.delete<any>(`/occupation/admin/soft-delete/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const restoreOccupation = (id: string, token: string) => {
  return http.delete<any>(`/occupation/admin/restore/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const OccupationService = {
  getAllOccupations,
  getOccupationById,
  getOccupationsWithCriteria,
  createOccupation,
  updateOccupation,
  deleteOccupation,
  softDeleteOccupation,
  restoreOccupation,
};

export default OccupationService;
