import React, { useState, useEffect } from "react";
import Select from "react-select";
import AuthService from "../../services/api/auth.service";
import LogService from "../../services/api/log.service";
import { ResponseLogDto } from "../../models/entities/log/response/response-log.dto";
import { ApiTypeEnum } from "../../models/enum/log/api-type.enum";
import { LogModuleEnum } from "../../models/enum/log/log-module.enum";
import { LogMethodEnum } from "../../models/enum/log/log-method.enum";
import { LogApiVersionEnum } from "../../models/enum/log/log-api-version.enum";
import { LogErrorTypeEnum } from "../../models/enum/log/log-error-type.enum";
import { GetLogsFilterDto } from "../../models/entities/log/dto/get-logs-filter.dto";
import { addMinutes, format } from "date-fns";
import { useForm } from "react-hook-form";
import { Box, Card, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { Line } from "react-chartjs-2";
import { Chart, ChartData, registerables } from "chart.js";
import Typography from "@mui/material/Typography";
import { Loader } from "../../components/loader/Loader";
Chart.register(...registerables);

interface LogsChartProps {
  logs: ResponseLogDto[];
}

interface LogGroupTimeInterface {
  logs: ResponseLogDto[];
  interval: number;
}

function groupByTime(logGroupTime: LogGroupTimeInterface) {
  // Initialize a new Map for the groups
  const groups = new Map();

  logGroupTime.logs.forEach((log) => {
    // Parse the date from the log's timestamp
    const date = new Date(log.createdAt);

    // "Round" the date to the nearest interval
    const rounded = addMinutes(
      date,
      Math.round((date.getMinutes() % logGroupTime.interval) / logGroupTime.interval) * logGroupTime.interval -
        date.getMinutes(),
    );

    // Format the date as a string to use as the group's key
    const key = format(rounded, "yyyy-MM-dd HH:mm");

    // Get the current group
    const group = groups.get(key) || { count: 0, createdAt: key };

    // Increase the count
    group.count += 1;

    // Update the group
    groups.set(key, group);
  });

  // Convert the Map to an Array
  const groupsArray = Array.from(groups.values());

  return groupsArray;
}

const data = {
  size: 200,
};

function LogsTable({ logs }: LogsChartProps) {
  // Render the logs data as a simple table for now
  return (
    <Card>
      <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 800 }}>
        <Table sx={{ height: 800 }}>
          <TableHead>
            <TableCell>ID</TableCell>
            <TableCell>ApiType</TableCell>
            <TableCell>ApiName</TableCell>
            <TableCell>ApiVersion</TableCell>
            <TableCell>Module</TableCell>
            <TableCell>Method</TableCell>
            <TableCell>Route</TableCell>
            <TableCell>Body</TableCell>
            <TableCell>Error</TableCell>
            <TableCell>CreatedAt</TableCell>
          </TableHead>
          <TableBody>
            {logs.map((log: ResponseLogDto) => (
              <TableRow key={log.id}>
                <TableCell>{log.id}</TableCell>
                <TableCell>{log.apiType}</TableCell>
                <TableCell>{log.apiName}</TableCell>
                <TableCell>{log.apiVersion}</TableCell>
                <TableCell>{log.module}</TableCell>
                <TableCell>{log.method}</TableCell>
                <TableCell>{log.route}</TableCell>
                <TableCell>{log.body}</TableCell>
                <TableCell>{log.error}</TableCell>
                <TableCell>{log.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

function LogsChart({ logs, interval }: LogGroupTimeInterface) {
  const groupedLogs = groupByTime({ logs, interval });

  const data = {
    labels: groupedLogs.map((log) => log.createdAt), // X-axis labels
    datasets: [
      {
        label: `Logs  (${logs.length})`, // Label for the dataset
        data: groupedLogs.map((log) => log.count), // Data for Y-axis
        backgroundColor: ["rgb(153, 102, 255)"],
        pointBackgroundColor: "rgb(255, 255, 255)",
        pointBorderColor: "rgb(75, 231, 72)",
        borderColor: ["rgb(153, 102, 255)"],
        borderWidth: 1,
        tension: 0.1,
      },
    ],
  };

  return (
    <Card sx={{ marginRight: 2, padding: 3, width: "100%" }}>
      <CardHeader title={"Logs"} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Line data={data} />
      </Box>
    </Card>
  );
}
const LogMonitoring = () => {
  const { register, handleSubmit, getValues, resetField } = useForm<GetLogsFilterDto>({ mode: "onChange" });

  const [token, setToken] = useState<any>("");

  // Les états
  const [logs, setLogs] = useState<ResponseLogDto[]>([]);
  const [selectedError, setSelectedError] = useState<{ label: string; value: string } | null>(null);
  const [selectedModule, setSelectedModule] = useState<{ label: string; value: string } | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<{ label: string; value: string } | null>(null);
  const [selectedApiType, setSelectedApiType] = useState<{ label: string; value: string } | null>(null);
  const [selectedApiVersion, setSelectedApiVersion] = useState<{ label: string; value: string } | null>(null);
  const [timeInterval, setTimeInterval] = useState<{ label: string; value: number }>({
    label: "30 minutes",
    value: 30,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Les options pour les tranches horaires
  const timeIntervalOptions = [
    { label: "30 minutes", value: 30 },
    { label: "1 heure", value: 60 },
    { label: "24 heures", value: 24 * 60 },
    { label: "1 mois", value: 24 * 60 * 30 },
  ];
  useEffect(() => {
    if (AuthService.getUserConnected() != "" || AuthService.getUserConnected() != null) {
      setToken(AuthService.getUserConnected());
    }
  }, [token]);

  function clearSelects() {
    setSelectedError(null);
    setSelectedModule(null);
    setSelectedMethod(null);
    setSelectedApiType(null);
    setSelectedApiVersion(null);
    setTimeInterval({ label: "30 minutes", value: 30 });
  }

  // L'effet pour charger les logs
  const onSubmit = handleSubmit(() => {
    setIsLoading(true);
    const filter: GetLogsFilterDto = {
      error: selectedError ? selectedError.value : undefined,
      module: selectedModule ? selectedModule.value : undefined,
      method: selectedMethod ? selectedMethod.value : undefined,
      apiType: selectedApiType ? selectedApiType.value : undefined,
      apiVersion: selectedApiVersion ? selectedApiVersion.value : undefined,
    };

    LogService.getLog(filter, token)
      .then((r) => {
        if (r.status === 201) {
          setLogs(r.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching logs", error);
      });
  });

  // Les options pour les listes déroulantes
  const errorOptions: { label: string; value: string }[] = Object.entries(LogErrorTypeEnum).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  const moduleOptions = Object.entries(LogModuleEnum).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  const methodOptions = Object.entries(LogMethodEnum).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  const apiTypeOptions = Object.entries(ApiTypeEnum).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  const apiVersionOptions = Object.entries(LogApiVersionEnum).map(([key, value]) => ({
    label: key,
    value: value,
  }));

  return (
    <div className="view--log">
      <Typography variant="h3" gutterBottom>
        Logs
      </Typography>
      <div>
        <div>
          <div style={{ marginTop: "20px" }}>
            <Select
              options={errorOptions}
              value={selectedError}
              onChange={(newValue) => setSelectedError(newValue)}
              placeholder="Select Error Type"
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Select
              options={moduleOptions}
              value={selectedModule}
              onChange={(newValue) => setSelectedModule(newValue)}
              placeholder="Select Module"
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Select
              options={methodOptions}
              value={selectedMethod}
              onChange={(newValue) => setSelectedMethod(newValue)}
              placeholder="Select Method"
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Select
              options={apiTypeOptions}
              value={selectedApiType}
              onChange={(newValue) => setSelectedApiType(newValue)}
              placeholder="Select API Type"
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Select
              options={apiVersionOptions}
              value={selectedApiVersion}
              onChange={(newValue) => setSelectedApiVersion(newValue)}
              placeholder="Select API Version"
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <form onSubmit={onSubmit} className="form-get-log">
              <button className="button-log">Récupérer</button>
              <button type="button" onClick={clearSelects} className="button-log-reset">
                Réinitialiser
              </button>
            </form>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div style={{ marginTop: "20px", position: "relative", left: "40%", top: "100px" }}>
              <Loader {...data} />
            </div>
          ) : (
            <div>
              <div style={{ marginTop: "40px" }}>
                <LogsChart logs={logs} interval={timeInterval.value} />
                <div style={{ marginTop: "20px" }}>
                  <Select
                    options={timeIntervalOptions}
                    value={timeInterval}
                    onChange={(newValue) => setTimeInterval(newValue ?? { label: "30 minutes", value: 30 })}
                    placeholder="Sélectionnez une tranche horaire"
                  />
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <LogsTable logs={logs} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogMonitoring;
