import React, { ChangeEvent, useEffect, useState } from "react";

import { MediaResponse } from "../../models/entities/media/response/media.response";
import { applySortFilterOther, getComparator } from "../../utils/array-user-list";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ArrayListToolbar from "../../components/array_manager/ArrayListToolbar";
import Scrollbar from "../../components/mui/scrollbar/Scrollbar";
import ArrayListHead from "../../components/array_manager/ArrayListHead";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import isEmpty from "../../utils/isempty.utils";
import AuthService from "../../services/api/auth.service";
import MediaService from "../../services/api/media.service";
import { FileUploader } from "react-drag-drop-files";

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "url", label: "Url", alignRight: false },
  { id: "key", label: "Key", alignRight: false },
  { id: "createdAt", label: "CreatedAt", alignRight: false },
  { id: "updatedAt", label: "UpdatedAt", alignRight: false },
  { id: "" },
];

const MockMedia: Array<MediaResponse> = [
  {
    id: "",
    url: "",
    key: "",
    createdAt: "",
    updatedAt: "",
  },
];

const media: MediaResponse = {
  id: "",
  url: "",
  key: "",
  createdAt: "",
  updatedAt: "",
};

const Media = () => {
  const { register, handleSubmit, getValues, resetField } = useForm<any>({ mode: "onChange" });

  const [open, setOpen] = useState<any>(null);
  const [openAnchoEl, setOpenAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<any>("asc");

  const [selected, setSelected] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<any>("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [medias, setMedias] = useState<Array<MediaResponse>>(MockMedia);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - medias.length) : 0;

  const filteredMedia = applySortFilterOther(medias, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredMedia.length && !!filterName;

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [titleModal, setTitleModal] = useState("Nouvel Media");
  const [mediaSelected, setMediaSelected] = useState<MediaResponse>(media);
  const [isButtonCreated, setIsButtonCreated] = useState(true);
  const [token, setToken] = useState<any>("");
  const [mediaChangeForUpdateOrSup, setMediaChangeForUpdateOrSup] = useState(media);
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const [file, setFile] = useState(null);
  const handleChange = (file: any) => {
    setFile(file);
  };
  const handleOpenMenu = (media: MediaResponse) => {
    setOpen(true);
    setMediaChangeForUpdateOrSup(media);
  };

  const handleAnchorEl = (event: any) => {
    setOpenAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setFile(null);
    setOpen(null);
  };

  const handleRequestSort = (event: ChangeEvent<HTMLInputElement>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = medias.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const onSubmit = handleSubmit((data) => {
    if (file) {
      const dataModelCreate: any = {
        file: file,
      };
      const formadata = new FormData();
      formadata.append("file", file);
      requestCreateApi(data.companyId, dataModelCreate, token);
    } else {
      setOpenFailed(true);
      setMessage("Aucune image selectionné");
      setTimeout(() => {
        setOpenFailed(false);
      }, 4000);
      return;
    }
  });

  const handleOpenCreateMedia = () => {
    resetField("file");
    resetField("cardId");
    setIsButtonCreated(true);
    setTitleModal("Nouvelle Media");
    handleOpen();
  };

  const handleDeleteMedia = () => {
    requestDeleteApi(token);
  };

  const requestCreateApi = (entrepriseId: string, data: any, token: string) => {
    MediaService.addAvatarGroup(entrepriseId, data, token)
      .then((r) => {
        if (r.status === 201) {
          MediaService.getAllMedia(token)
            .then((r) => {
              if (r.status === 200) {
                setMedias(r.data);
                setOpenCreated(true);
                setMessage("Media crée");
                setOpenModal(false);
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("Media non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Media non crée");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const requestDeleteApi = (token: string) => {
    MediaService.removeMediaAdmin(mediaChangeForUpdateOrSup.id, token)
      .then((r) => {
        if (r.status === 200) {
          MediaService.getAllMedia(token)
            .then((r) => {
              if (r.status === 200) {
                setMedias(r.data);
                setOpenCreated(true);
                setMessage("Media " + mediaChangeForUpdateOrSup.id + " supprimé");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("Media non récuperer");
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Impossible de supprimé l'Media");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      MediaService.getAllMedia(token)
        .then((r) => {
          if (r.status === 200) {
            setMedias(r.data);
          }
        })
        .catch((reason) => console.log(""));
    }
  }, [token]);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    setFile(file);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            border: "2px solid transparent",
          }}
        >
          <div className="container-modal">
            <div className="header-modal">
              <div className="title-header">{titleModal}</div>
              <img
                className="button-close"
                onClick={handleClose}
                src="/assets/logo/close_simple.svg"
                alt="close button"
              />
            </div>
            <form onSubmit={onSubmit} className="form-container">
              <div className="container-input-modal">
                <div className="title-input">
                  Media Avatar entreprise <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  placeholder="companyId"
                  {...register("companyId")}
                />
              </div>
              <div className="container-input-modal" style={{ alignItems: "center" }}>
                <div className="file-drop-area">
                  <span className="fake-btn">Choisir son image</span>
                  <span className="file-msg">or drag and drop image here</span>
                  <input className="file-input" accept="image/*" onChange={handleImageChange} type="file" />
                </div>
              </div>
              <button className="button-upload">Créer </button>
            </form>
          </div>
        </div>
      </Modal>
      <div className="view-array">
        <Container sx={{ maxWidth: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manager Medias
            </Typography>
            <></>
          </Stack>

          <Card>
            <ArrayListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar sx={{ height: 400 }}>
              <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 400 }}>
                <Table sx={{ height: 400 }}>
                  <ArrayListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={medias.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredMedia
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index: number) => {
                        const { id, url, key, createdAt, updatedAt } = row;
                        const selectedUser = selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            </TableCell>

                            <TableCell align="left">{id}</TableCell>

                            <TableCell align="left">{url}</TableCell>

                            <TableCell align="left">{key}</TableCell>

                            <TableCell align="left">{createdAt.toString()}</TableCell>

                            <TableCell align="left">{updatedAt.toString()}</TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={handleAnchorEl}>
                                <MoreVertIcon onClick={() => handleOpenMenu(row)} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 35]}
              component="div"
              count={medias.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <Popover
          open={Boolean(open)}
          anchorEl={openAnchoEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem sx={{ color: "error.main" }} onClick={() => handleDeleteMedia()}>
            <DeleteIcon sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Media;
