export enum LogMethodEnum {
  CREATE_ADDRESS = "create-address",
  REMOVE_ADDRESS = "remove-address",
  RESTORE_ADDRESS = "restore-address",
  SOFT_REMOVE_ADDRESS = "soft-remove-address",
  UPDATE_ADDRESS = "update-address",
  DELETE_MAIL = "delete-mail",
  SEND_MAIL = "send-mail",
  ADD_CONNECTED_CARD = "add-connected-card",
  ADD_SAVED_CARD = "add-saved-card",
  ADD_VIEW_COUNT = "add-view-count",
  CREATE_CARD = "create-card",
  DELETE_CARD = "delete-card",
  REMOVE_CONNECTED_CARD = "remove-connected-card",
  REMOVE_SAVED_CARD = "remove-saved-card",
  RESTORE_CARD = "restore-card",
  SOFT_DELETE_CARD = "soft-delete-card",
  UPDATE_CARD = "update-card",
  ADD_COMPANY_EMPLOYEE = "add-company-employee",
  CREATE_CARD_PRESET = "create-card-preset",
  CREATE_COMPANY = "create-company",
  GIVE_RIGHT_TO_EMPLOYEE = "give-right-to-employee",
  REMOVE_CARD_PRESET = "remove-card-preset",
  REMOVE_COMPANY = "remove-company",
  REMOVE_COMPANY_EMPLOYEE = "remove-company-employee",
  RESTORE_CARD_PRESET = "restore-card-preset",
  RESTORE_COMPANY = "restore-company",
  SOFT_REMOVE_CARD_PRESET = "soft-remove-card-preset",
  SOFT_REMOVE_COMPANY = "soft-remove-company",
  TRANSFER_OWNERSHIP_COMPANY = "transfer-ownership-company",
  UPDATE_CARD_PRESET = "update-card-preset",
  UPDATE_COMPANY = "update-company",
  ADD_MESSAGE_WITH_MEDIA = "add-message-with-media",
  CREATE_CONNECTED_USER = "create-connected-user",
  CREATE_CONVERSATION_MESSAGE = "create-conversation-message",
  CREATE_JOINED_CONVERSATION = "create-joined-conversation",
  DELETE_ALL_JOINED_CONVERSATION_WITH_SOCKET_ID = "delete-all-joined-conversation-with-socket-id",
  DELETE_JOINED_CONVERSATION = "delete-joined-conversation",
  REMOVE_ALL_CONNECTED_USER = "remove-all-connected-user",
  REMOVE_ALL_JOINED_CONVERSATION_BY_CONVERSATION_ID = "remove-all-joined-conversation-by-conversation-id",
  REMOVE_ALL_JOINED_PROFILE_BY_CONVERSATION_ID = "remove-all-joined-profile-by-conversation-id",
  REMOVE_CONNECTED_USER_BY_SOCKET_ID = "remove-connected-user-by-socket-id",
  REMOVE_MESSAGE_CONVERSATION = "remove-message-conversation",
  SOFT_REMOVE_MESSAGE_CONVERSATION = "soft-remove-message-conversation",
  ADD_CARD_TO_GROUP = "add-card-to-group",
  CREATE_GROUP = "create-group",
  DELETE_GROUP = "delete-group",
  DELETE_GROUP_MEMBERSHIP = "delete-group-membership",
  GIVE_ADMIN_RIGHT_TO_GROUP = "give-admin-right-to-group",
  REMOVE_ADMIN_RIGHT_GROUP = "remove-admin-right-group",
  REMOVE_CARD_FROM_GROUP = "remove-card-from-group",
  RESTORE_GROUP = "restore-group",
  SOFT_DELETE_GROUP = "soft-delete-group",
  UPDATE_GROUP = "update-group",
  ADD_AVATAR_COMPANY = "add-avatar-company",
  ADD_AVATAR_GROUP_MEDIA = "add-avatar-group-media",
  ADD_AVATAR_PROFILE = "add-avatar-profile",
  ADD_BANNER_COMPANY_MEDIA = "add-banner-company-media",
  ADD_BANNER_GROUP_MEDIA = "add-banner-group-media",
  ADD_BANNER_PROFILE_MEDIA = "add-banner-profile-media",
  ADD_CARD_MEDIA = "add-card-media",
  ADD_CARD_PRESET_MEDIA = "add-card-preset-media",
  REMOVE_MEDIA = "remove-media",
  RESTORE_MEDIA = "restore-media",
  SOFT_REMOVE_MEDIA = "soft-remove-media",
  UPLOAD_MEDIA = "upload-media",
  CREATE_NOTIFICATION = "create-notification",
  CREATE_NOTIFICATION_ADMIN = "create-notification-admin",
  MARK_NOTIFICATION_AS_READ = "mark-notification-as-read",
  REMOVE_NOTIFICATION = "remove-notification",
  RESTORE_NOTIFICATION = "restore-notification",
  SOFT_REMOVE_NOTIFICATION = "soft-remove-notification",
  CREATE_OCCUPATION = "create-occupation",
  DELETE_OCCUPATION = "delete-occupation",
  RESTORE_OCCUPATION = "restore-occupation",
  SOFT_DELETE_OCCUPATION = "soft-delete-occupation",
  UPDATE_OCCUPATION = "update-occupation",
  CREATE_INVOICE = "create-invoice",
  CREATE_PRICE = "create-price",
  REMOVE_PRICE = "remove-price",
  RESTORE_PRICE = "restore-price",
  SOFT_REMOVE_PRICE = "soft-remove-price",
  UPDATE_PRICE = "update-price",
  CREATE_PRODUCT = "create-product",
  REMOVE_PRODUCT = "remove-product",
  RESTORE_PRODUCT = "restore-product",
  SOFT_REMOVE_PRODUCT = "soft-remove-product",
  UPDATE_PRODUCT = "update-product",
  ATTACH_CREDIT_CARD = "attach-credit-card",
  CANCEL_SUBSCRIPTION_STRIPE = "cancel-subscribtion-stripe",
  CHARGE_STRIPE = "charge-stripe",
  CONSTRUCT_EVENT_FROM_PAYLOAD_STRIPE = "construct-event-from-payload-stripe",
  CREATE_CUSTOMER_STRIPE = "create-customer-stripe",
  CREATE_PRICE_STRIPE = "create-price-stripe",
  CREATE_PRODUCT_STRIPE = "create-product-stripe",
  CREATE_REFERRAL_STRIPE = "create-referral-stripe",
  CREATE_STRIPE_CUSTOMER = "create-stripe-customer",
  CREATE_SUBSCRIBTION_STRIPE = "create-subscribtion-stripe",
  DELETE_COUPON_STRIPE = "delete-coupon-stripe",
  REMOVE_PRICE_STRIPE = "remove-price-stripe",
  REMOVE_PRODUCT_STRIPE = "remove-product-stripe",
  SET_DEFAULT_CREDIT_CARD_STRIPE = "set-default-credit-card-stripe",
  UPDATE_COUPON_STRIPE = "update-coupon-stripe",
  UPDATE_PRICE_STRIPE = "update-price-stripe",
  UPDATE_PRODUCT_STRIPE = "update-product-stripe",
  CREATE_STRIPE_EVENT = "create-stripe-event",
  ASSIGN_PROFILE_TO_SUBSCRIPTION = "assign-profile-to-subscription",
  CANCEL_SUBSCRIPTION = "cancel-subscription",
  CREATE_SUBSCRIPTION = "create-subscription",
  CREATE_USER_REFERRAL_CODE = "create-user-referral-code",
  REMOVE_PROFILE_TO_SUBSCRIPTION = "remove-profile-to-subscription",
  REMOVE_SUBSCRIPTION = "remove-subscription",
  UPDATE_ACCOUNT_STATUS = "update-account-status",
  UPDATE_SUBSCRIPTION = "update-subscription",
  CREATE_PROFILE = "create-profile",
  DELETE_PROFILE = "delete-profile",
  RESTORE_PROFILE = "restore-profile",
  SHIFT_PROFILE_OWNER = "shift-profile-owner",
  SOFT_DELETE_PROFILE = "soft-delete-profile",
  UPDATE_OCCUPATION_PROFILE = "update-occupation-profile",
  UPDATE_PROFILE = "update-profile",
  UPDATE_ROLE_PROFILE = "update-role-profile",
  CREATE_SOCIAL_NETWORK = "create-social-network",
  DELETE_SOCIAL_NETWORK = "delete-social-network",
  RESTORE_SOCIAL_NETWORK = "restore-social-network",
  SOFT_DELETE_SOCIAL_NETWORK = "soft-delete-social-network",
  UPDATE_SOCIAL_NETWORK = "update-social-network",
  CREATE_SAVE_LOGIN = "create-save-login",
  CREATE_USER = "create-user",
  DELETE_USER = "delete-user",
  LOGIN_OF_USER = "login-of-user",
  REMOVE_REFRESH_TOKEN = "remove-refresh-token",
  RESTORE_USER = "restore-user",
  SET_CURRENT_REFRESH_TOKEN = "set-current-refresh-token",
  SET_REFERRAL_CODE = "set-referral-code",
  SOFT_DELETE_USER = "soft-delete-user",
  UPDATE_FCM_TOKEN = "update-fcm-token",
  UPDATE_USER = "update-user",
  UPDATE_USER_CREDENTIAL = "update-user-credential",
  UPDATE_USER_ROLE = "update-user-role",
}
