import http from "../../http-common";

const getListSubscription = (stripePriceId: string, token: string) => {
  return http.get<any>(`/subscription/public/list-subscription/${stripePriceId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllSubscriptionsCount = (token: string) => {
  return http.get<any>("/subscription/admin/get-all-active-subscriptions-count", {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllSubscriptions = (token: string) => {
  return http.get<any>("/subscription/admin/get-all-subscriptions", { headers: { Authorization: "Bearer " + token } });
};

const cancelSubscriptionAdmin = (subscriptionId: string, stripId: string, token: string) => {
  return http.put<any>(
    `/subscription/admin/cancel-subscription/${subscriptionId}/${stripId}`,
    {},
    { headers: { Authorization: "Bearer " + token } },
  );
};

const SubsciptionService = {
  getListSubscription,
  getAllSubscriptionsCount,
  getAllSubscriptions,
  cancelSubscriptionAdmin,
};

export default SubsciptionService;
