import React, { ChangeEvent, useEffect, useState } from "react";
import { SocialNetworkModel } from "../../models/entities/social-network/response/social-network.response";
import { applySortFilterEnterprise, applySortFilterOther, getComparator } from "../../utils/array-user-list";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ArrayListToolbar from "../../components/array_manager/ArrayListToolbar";
import Scrollbar from "../../components/mui/scrollbar/Scrollbar";
import ArrayListHead from "../../components/array_manager/ArrayListHead";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import isEmpty from "../../utils/isempty.utils";
import AuthService from "../../services/api/auth.service";
import SocialNetworkService from "../../services/api/social-network.service";
import { UpdateSocialNetworkModel } from "../../models/entities/social-network/request/update-social-network.request";
import { CreateSocialNetworkModel } from "../../models/entities/social-network/request/create-social-network.request";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "url", label: "Url", alignRight: false },
  { id: "icon", label: "Icon", alignRight: false },
  { id: "color", label: "Color", alignRight: false },
  { id: "createdAt", label: "CreatedAt", alignRight: false },
  { id: "updatedAt", label: "UpdatedAt", alignRight: false },
  { id: "" },
];

const MockSocialNetwork: Array<SocialNetworkModel> = [
  {
    id: "",
    name: "",
    url: "",
    icon: "",
    color: "",
    createdAt: "",
    updatedAt: "",
  },
];
const socialNetwork: SocialNetworkModel = {
  id: "",
  name: "",
  url: "",
  icon: "",
  color: "",
  createdAt: "",
  updatedAt: "",
};

const SocialNetwork = () => {
  const { register, handleSubmit, getValues, resetField } = useForm<SocialNetworkModel>({ mode: "onChange" });

  const [open, setOpen] = useState<any>(null);
  const [openAnchoEl, setOpenAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<any>("asc");

  const [selected, setSelected] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<any>("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [socialNetworks, setSocialNetworks] = useState<Array<SocialNetworkModel>>(MockSocialNetwork);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [titleModal, setTitleModal] = useState("Nouvel Occupation");
  const [socialNetworkSelected, setSocialNetworkSelected] = useState<SocialNetworkModel>(socialNetwork);
  const [isButtonCreated, setIsButtonCreated] = useState(true);
  const [token, setToken] = useState<any>("");
  const [socialNetworkChangeForUpdateOrSup, setSocialNetworkChangeForUpdateOrSup] = useState(socialNetwork);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - socialNetworks.length) : 0;

  const filteredSocialNetworks = applySortFilterEnterprise(socialNetworks, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredSocialNetworks.length && !!filterName;

  const handleOpenMenu = (socialNetwork: SocialNetworkModel) => {
    setOpen(true);
    setSocialNetworkChangeForUpdateOrSup(socialNetwork);
  };

  const handleAnchorEl = (event: any) => {
    setOpenAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event: ChangeEvent<HTMLInputElement>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = socialNetworks.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const onSubmit = handleSubmit((data) => {
    const dataModelCreate: CreateSocialNetworkModel = {
      name: data.name,
      url: data.url,
      icon: data.icon,
      color: data.color,
    };
    const dataModelUpdate: UpdateSocialNetworkModel = {
      id: socialNetworkChangeForUpdateOrSup.id,
      name: data.name,
      url: data.url,
      icon: data.icon,
      color: data.color,
    };
    if (isButtonCreated === true) {
      requestCreateApi(dataModelCreate, token);
    } else {
      requestModifyApi(dataModelUpdate, token);
    }
  });

  const handleOpenCreateSocialNetwork = () => {
    resetField("name");
    setIsButtonCreated(true);
    setTitleModal("Nouvelle socialNetwork");
    handleOpen();
  };
  const handleOpenModifySocialNetwork = (valueSocialNetwork: SocialNetworkModel) => {
    resetField("name");
    setIsButtonCreated(false);
    setSocialNetworkSelected(valueSocialNetwork);
    setTitleModal("Modification de l'socialNetwork");
    handleOpen();
  };

  const handleDeleteSocialNetwork = () => {
    requestDeleteApi(token);
  };

  const requestCreateApi = (data: CreateSocialNetworkModel, token: string) => {
    SocialNetworkService.createSocialNetwork(data, token)
      .then((r) => {
        if (r.status === 204) {
          SocialNetworkService.getAllSocialNetworks(token)
            .then((r) => {
              if (r.status === 200) {
                setSocialNetworks(r.data);
                setOpenCreated(true);
                setMessage("socialNetwork crée");
                setOpenModal(false);

                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("socialNetwork non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("socialNetwork non crée");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const requestDeleteApi = (token: string) => {
    SocialNetworkService.deleteSocialNetwork(socialNetworkChangeForUpdateOrSup.id, token)
      .then((r) => {
        if (r.status === 204) {
          SocialNetworkService.getAllSocialNetworks(token)
            .then((r) => {
              if (r.status === 200) {
                setSocialNetworks(r.data);
                setOpenCreated(true);
                setMessage("socialNetwork " + socialNetworkChangeForUpdateOrSup.id + " supprimé");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("socialNetwork non récuperer");
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Impossible de supprimé l'socialNetwork");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const requestModifyApi = (data: UpdateSocialNetworkModel, token: string) => {
    SocialNetworkService.updateSocialNetwork(socialNetworkChangeForUpdateOrSup.id, data, token)
      .then((r) => {
        if (r.status === 204) {
          SocialNetworkService.getAllSocialNetworks(token)
            .then((r) => {
              if (r.status === 200) {
                setSocialNetworks(r.data);
                setOpenCreated(true);
                setMessage("socialNetwork mise a jours");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("social network non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("social network non récuperer");
        setOpenModal(false);
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      SocialNetworkService.getAllSocialNetworks(token)
        .then((r) => {
          if (r.status === 200) {
            setSocialNetworks(r.data);
          }
        })
        .catch((reason) => console.log(""));
    }
  }, [token]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            border: "2px solid transparent",
          }}
        >
          <div className="container-modal">
            <div className="header-modal">
              <div className="title-header">{titleModal}</div>
              <img
                className="button-close"
                onClick={handleClose}
                src="/assets/logo/close_simple.svg"
                alt="close button"
              />
            </div>
            <form onSubmit={onSubmit} className="form-container">
              <div className="container-input-modal">
                <div className="title-input">
                  Name <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={socialNetworkSelected.name}
                  placeholder="name"
                  {...register("name")}
                />
              </div>
              <div className="container-input-modal">
                <div className="title-input">
                  url <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={socialNetworkSelected.url}
                  placeholder="url"
                  {...register("url")}
                />
              </div>
              <div className="container-input-modal">
                <div className="title-input">
                  icon <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={socialNetworkSelected.icon}
                  placeholder="icon"
                  {...register("icon")}
                />
              </div>
              <div className="container-input-modal">
                <div className="title-input">
                  color <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={socialNetworkSelected.color}
                  placeholder="color"
                  {...register("color")}
                />
              </div>
              {isButtonCreated === true && <button className="button-upload">Créer </button>}
              {isButtonCreated === false && <button className="button-upload">Modifier</button>}
            </form>
          </div>
        </div>
      </Modal>
      <div className="view-array">
        <Container sx={{ maxWidth: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manager SocialNetworks
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleOpenCreateSocialNetwork()}
              sx={{ background: "rgb(34, 10, 110)" }}
              startIcon={<AddIcon />}
            >
              New SocialNetwork
            </Button>
          </Stack>

          <Card>
            <ArrayListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar sx={{ height: 400 }}>
              <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 400 }}>
                <Table sx={{ height: 400 }}>
                  <ArrayListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={socialNetworks.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredSocialNetworks
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index: number) => {
                        const { id, name, url, icon, color, createdAt, updatedAt } = row;
                        const selectedUser = selected.indexOf(name) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                            </TableCell>

                            <TableCell align="left">{id}</TableCell>

                            <TableCell align="left">{name}</TableCell>

                            <TableCell align="left">{url}</TableCell>

                            <TableCell align="left">{icon}</TableCell>
                            <TableCell align="left">{color}</TableCell>
                            <TableCell align="left">{createdAt}</TableCell>
                            <TableCell align="left">{updatedAt}</TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={handleAnchorEl}>
                                <MoreVertIcon onClick={() => handleOpenMenu(row)} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 35]}
              component="div"
              count={socialNetworks.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <Popover
          open={Boolean(open)}
          anchorEl={openAnchoEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            sx={{ color: "warning.main" }}
            onClick={() => handleOpenModifySocialNetwork(socialNetworkChangeForUpdateOrSup)}
          >
            <ChangeCircleIcon sx={{ mr: 2 }} />
            update
          </MenuItem>
          <MenuItem sx={{ color: "error.main" }} onClick={() => handleDeleteSocialNetwork()}>
            <DeleteIcon sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SocialNetwork;
