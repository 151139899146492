import http from "../http-common";

const getMediaWithId = (mediaId: string, token: string) => {
  return http.get<any>(`/media/public/get-media-with-id/${mediaId}`, { headers: { Authorization: "Bearer " + token } });
};

const getAllMedia = (token: string) => {
  return http.get<any>("/media/public/get-all-media", { headers: { Authorization: "Bearer " + token } });
};

const addAvatarCompany = (companyId: string, data: any, token: string) => {
  return http.post<any>(`/media/public/add-avatar-company/${companyId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addAvatarGroup = (groupId: string, data: any, token: string) => {
  return http.post<any>(`/media/public/add-avatar-group/${groupId}`, data, {
    headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer " + token },
  });
};

const addAvatarProfile = (profileId: string, data: any, token: string) => {
  return http.post<any>(`/media/public/add-avatar-profile/${profileId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addBannerCompany = (companyId: string, data: any, token: string) => {
  return http.post<any>(`/media/public/add-banner-company/${companyId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addBannerGroup = (groupId: string, data: any, token: string) => {
  return http.post<any>(`/media/public/add-banner-group/${groupId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addBannerProfile = (profileId: string, data: any, token: string) => {
  return http.post<any>(`/media/public/add-banner-profile/${profileId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addCardMedia = (cardId: string, data: any, token: string) => {
  return http.post<any>(`/media/public/add-card-media/${cardId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const removeMedia = (mediaId: string, token: string) => {
  return http.delete<any>(`/media/public/remove-media/${mediaId}`, { headers: { Authorization: "Bearer " + token } });
};

const removeMediaAdmin = (mediaId: string, token: string) => {
  return http.delete<any>(`/media/admin/remove-media/${mediaId}`, { headers: { Authorization: "Bearer " + token } });
};

const restoreMediaAdmin = (mediaId: string, token: string) => {
  return http.delete<any>(`/media/admin/restore-media/${mediaId}`, { headers: { Authorization: "Bearer " + token } });
};

const softRemoveMediaAdmin = (mediaId: string, token: string) => {
  return http.delete<any>(`/media/admin/soft-remove-media/${mediaId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const MediaService = {
  getMediaWithId,
  getAllMedia,
  addAvatarCompany,
  addAvatarGroup,
  addAvatarProfile,
  addBannerCompany,
  addBannerGroup,
  addBannerProfile,
  addCardMedia,
  removeMedia,
  removeMediaAdmin,
  restoreMediaAdmin,
  softRemoveMediaAdmin,
};

export default MediaService;
