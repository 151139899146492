import http from "../http-common";
import { UserCreateModel } from "../../models/entities/user/user.create.model";
import { UserModel } from "../../models/entities/user/user.response.model";
import { GetUserWithCriteriaDto } from "../../models/entities/user/dto/get-user-with-criteria.dto";
import { GenerateUserDto } from "../../models/entities/user/dto/generate-user.dto";

const getMyLastLogin = (token: string) => {
  return http.get<Array<any>>("/admin/", { headers: { Authorization: "Bearer " + token } });
};

const getUserLastLogin = (id: string, token: string) => {
  return http.get<Array<any>>(`/user/admin/get-user-last-login/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAll = (token: string) => {
  return http.get<Array<any>>("/user/admin/get-all-user", { headers: { Authorization: "Bearer " + token } });
};

const get = (id: string, token: string) => {
  return http.get<any>(`/user/admin/get-user-by-id/${id}`, { headers: { Authorization: "Bearer " + token } });
};
const getMe = (token: string) => {
  return http.get<any>("/user/public/get-me", { headers: { Authorization: "Bearer " + token } });
};

const findWithCriteria = (data: GetUserWithCriteriaDto, token: string) => {
  return http.post<any>("/user/admin/criteria", data, { headers: { Authorization: "Bearer " + token } });
};

const createUser = (data: UserCreateModel, token: string) => {
  return http.post<any>("/user/admin/create", data, { headers: { Authorization: "Bearer " + token } });
};

const createSuperAdmin = (password: string, data: UserCreateModel, token: string) => {
  return http.post<any>(`/user/admin/create/${password}`, data, { headers: { Authorization: "Bearer " + token } });
};

const generateUserFromMail = (data: GenerateUserDto, token: string) => {
  return http.post<any>("/user/admin/generate-user-from-mail", data, { headers: { Authorization: "Bearer " + token } });
};

const restoreUser = (data: any, token: string) => {
  return http.post<any>("/user/admin/restore-user", data, { headers: { Authorization: "Bearer " + token } });
};

const update = (id: string, data: UserModel, token: string) => {
  return http.put<any>(`/user/admin/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};

const updateRole = (id: string, data: UserModel, token: string) => {
  return http.put<any>(`/user/admin/${id}/role`, data, { headers: { Authorization: "Bearer " + token } });
};

const updateMe = (id: string, data: UserModel, token: string) => {
  return http.put<any>("/user/public/update-me", data, { headers: { Authorization: "Bearer " + token } });
};

const updatePassword = (id: string, data: UserModel, token: string) => {
  return http.put<any>("/user/public/update-password", data, { headers: { Authorization: "Bearer " + token } });
};

const remove = (id: string, token: string) => {
  return http.delete<any>(`/user/admin/${id}`, { headers: { Authorization: "Bearer " + token } });
};
const deleteMe = (token: string) => {
  return http.delete<any>("/user/public/delete-me", { headers: { Authorization: "Bearer " + token } });
};

const fullDelete = (id: string, token: string) => {
  return http.delete<any>(`/user/admin/full-delete/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const UserService = {
  getAll,
  get,
  getMyLastLogin,
  getUserLastLogin,
  getMe,
  createUser,
  findWithCriteria,
  createSuperAdmin,
  generateUserFromMail,
  restoreUser,
  update,
  updateRole,
  updateMe,
  remove,
  updatePassword,
  deleteMe,
  fullDelete,
};

export default UserService;
