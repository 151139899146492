import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import isEmpty from "../../utils/isempty.utils";
import MuiUserUtils from "../../utils/mui.user.utils";

export const AccountProfile = () => {
  const user = useSelector((state: any) => state.userReducer);
  const [name, setName] = useState<string>("test gggg");
  useEffect(() => {
    if (!isEmpty(user)) {
      setName(user.username);
    } else {
      setName("Test test");
    }
  }, [user]);

  return (
    <Card sx={{ borderRadius: 10, padding: 1 }}>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            {...MuiUserUtils.stringAvatar(name)}
            sx={{
              backgroundColor: "rgba(34, 10, 110,1)",
              height: 80,
              fontSize: 20,
              mb: 2,
              width: 80,
            }}
          />
          <Typography gutterBottom variant="h5">
            {name}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
