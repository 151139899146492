import React, { ChangeEvent, useEffect, useState } from "react";
import { UserModel } from "../../models/entities/user/user.response.model";
import { applySortFilterUsers, getComparator } from "../../utils/array-user-list";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Select from "react-select";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ArrayListToolbar from "../../components/array_manager/ArrayListToolbar";
import Scrollbar from "../../components/mui/scrollbar/Scrollbar";
import ArrayListHead from "../../components/array_manager/ArrayListHead";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { jsx } from "@emotion/react";
import { UserRoleEnum } from "../../models/enum/user-role.enum";
import { UserCreateModel } from "../../models/entities/user/user.create.model";
import UserService from "../../services/api/user.service";
import AuthService from "../../services/api/auth.service";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "username", label: "Username", alignRight: false },
  { id: "mail", label: "Mail", alignRight: false },
  { id: "profiles", label: "Profiles", alignRight: false },
  { id: "roles", label: "Roles", alignRight: false },
  { id: "createdAt", label: "CreatedAt", alignRight: false },
  { id: "" },
];

const MockUser: Array<UserModel> = [
  {
    id: "",
    username: "",
    mail: "",
    profiles: [],
    roles: [],
    createdAt: "",
    stripeCustomerId: "",
  },
];

const user: UserModel = {
  id: "",
  username: "",
  mail: "",
  profiles: [],
  roles: [],
  createdAt: "",
  stripeCustomerId: "",
};
const User = () => {
  const { register, handleSubmit, getValues, resetField } = useForm<UserModel>({ mode: "onChange" });

  const [open, setOpen] = useState<any>(null);
  const [openAnchoEl, setOpenAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<any>("asc");

  const [selected, setSelected] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<any>("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [users, setUsers] = useState<Array<UserModel>>(MockUser);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredCompagny = applySortFilterUsers(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredCompagny.length && !!filterName;

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [titleModal, setTitleModal] = useState("Nouvel utilisateur");
  const [userSelected, setUserSelected] = useState<UserModel>(user);
  const [isButtonCreated, setIsButtonCreated] = useState(true);
  const [roles, setRoles] = useState([
    UserRoleEnum.PUBLIC,
    UserRoleEnum.COMPANY_ACCOUNT,
    UserRoleEnum.ADMIN,
    UserRoleEnum.SUPER_ADMIN,
  ]);
  const [token, setToken] = useState<any>("");
  const [selectedRoles, setSelectRoles] = useState([]);
  const [allRoles, setAllRoles] = useState<any>([]);
  const [userChangeForUpdateOrSup, setUserChangeForUpdateOrSup] = useState(user);
  const [defaultRoles, setDefaultRoles] = useState({});
  const [messageErrorMail, setMessageErrorMail] = useState<any>("");
  const [messageErrorUsername, setMessageErrorUsername] = useState<any>("");
  const handleOpenMenu = (user: UserModel) => {
    setOpen(true);
    setUserChangeForUpdateOrSup(user);
  };

  const handleAnchorEl = (event: any) => {
    setOpenAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event: ChangeEvent<HTMLInputElement>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  function validateEmail(input: string): string {
    const regex = new RegExp("^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)$");
    if (regex.test(input)) {
      return "";
    } else {
      return "Adresse e-mail invalide.";
    }
  }
  const onSubmit = handleSubmit((data) => {
    if (validateEmail(data.mail) != "Adresse e-mail invalide.") {
      if (isButtonCreated === true) {
        const dataModelCreate: UserCreateModel = {
          mail: data.mail,
          username: data.username,
          password: data.password === undefined ? "" : data.password,
          roles: selectedRoles.map((role: any) => role.label),
        };
        requestCreateApi(dataModelCreate, token);
      } else {
        requestModifyApi(data, token);
      }
    } else {
      setMessageErrorMail("Adresse Mail invalide");
    }
  });

  const handleOpenCreateUser = () => {
    resetField("username");
    resetField("mail");
    resetField("profiles");
    resetField("roles");
    setIsButtonCreated(true);
    setTitleModal("Nouvelle utilisateur");
    setRoles([UserRoleEnum.PUBLIC, UserRoleEnum.COMPANY_ACCOUNT, UserRoleEnum.ADMIN, UserRoleEnum.SUPER_ADMIN]);
    handleOpen();
  };
  const handleOpenModifyUser = (valueUser: UserModel) => {
    resetField("id");
    resetField("username");
    resetField("mail");
    resetField("profiles");
    resetField("roles");
    setIsButtonCreated(false);
    setUserSelected(valueUser);
    setRoles(valueUser.roles);
    returnTabToProfiles(true);
    setTitleModal("Modification de l'utilisateur");
    handleOpen();
  };

  const handleDeleteUser = () => {
    requestDeleteApi(token);
  };

  const requestCreateApi = (data: UserCreateModel, token: string) => {
    UserService.createUser(data, token)
      .then((r) => {
        if (r.status === 201) {
          UserService.getAll(token)
            .then((r) => {
              if (r.status === 200) {
                setUsers(r.data);
                setOpenCreated(true);
                setMessage("User crée");
                setOpenModal(false);
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("User non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (err.response.data.message === "Username already exists") {
          setMessageErrorUsername(err.response.data.message);
        } else if (err.response.data.message === "Mail already exists") {
          setMessageErrorMail(err.response.data.message);
        }
        setOpenFailed(true);
        setMessage("User non crée");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const requestDeleteApi = (token: string) => {
    UserService.fullDelete(userChangeForUpdateOrSup.id, token)
      .then((r) => {
        if (r.status === 204) {
          UserService.getAll(token)
            .then((r) => {
              if (r.status === 200) {
                setUsers(r.data);
                setOpenCreated(true);
                setMessage("User " + userChangeForUpdateOrSup.id + " supprimé");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("User non récuperer");
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Impossible de supprimé le user");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const requestModifyApi = (data: UserModel, token: string) => {
    UserService.update(userChangeForUpdateOrSup.id, data, token)
      .then((r) => {
        if (r.status === 200) {
          UserService.getAll(token)
            .then((r) => {
              if (r.status === 200) {
                setUsers(r.data);
                setOpenCreated(true);
                setMessage("User mise a jours");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setOpenFailed(true);
              setMessage("User non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 1000);
            });
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (err.response.data.message === "Username already exists") {
          setMessageErrorUsername(err.response.data.message);
        } else if (err.response.data.message === "Mail already exists") {
          setMessageErrorMail(err.response.data.message);
        }
        setOpenFailed(true);
        setMessage("User non récuperer");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const returnTabToProfiles = (update: boolean) => {
    const tab: any = [];
    roles.forEach((pro, index) => {
      tab.push({ value: index, label: pro });
    });
    setAllRoles(tab);
    if (update) {
      setDefaultRoles(tab);
    }
  };
  const handleChangeInputSelectionUser = (selectedOption: any) => {
    setSelectRoles(selectedOption);
  };

  const rolesSelect = (database: any) => {
    return <Select defaultValue={defaultRoles} options={database} onChange={handleChangeInputSelectionUser} isMulti />;
  };

  useEffect(() => {
    returnTabToProfiles(false);
    if (AuthService.getUserConnected() != "" || AuthService.getUserConnected() != null) {
      setToken(AuthService.getUserConnected());
      UserService.getAll(token)
        .then((r) => {
          if (r.status === 200) {
            setUsers(r.data);
          }
        })
        .catch((reason) => console.log(""));
    }
  }, [token]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            border: "2px solid transparent",
          }}
        >
          <div className="container-modal">
            <div className="header-modal">
              <div className="title-header">{titleModal}</div>
              <img
                className="button-close"
                onClick={handleClose}
                src="/assets/logo/close_simple.svg"
                alt="close button"
              />
            </div>
            <form onSubmit={onSubmit} className="form-container">
              <div className="container-input-modal">
                <div className="title-input">
                  Username <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={userSelected.username}
                  placeholder="username"
                  {...register("username")}
                />
                {messageErrorUsername != "" && (
                  <div className="title-input" style={{ fontSize: "10px", color: "red" }}>
                    {messageErrorUsername}
                  </div>
                )}
              </div>
              <div className="container-input-modal">
                <div className="title-input">
                  Mail <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={userSelected.mail}
                  placeholder="mail"
                  {...register("mail")}
                />
                {messageErrorMail != "" && (
                  <div className="title-input" style={{ fontSize: "10px", color: "red" }}>
                    {messageErrorMail}
                  </div>
                )}
              </div>
              {isButtonCreated === true && (
                <div className="container-input-modal">
                  <div className="title-input">
                    Password <span>*</span>
                  </div>
                  <input
                    type="text"
                    className="input-container-modal"
                    placeholder="password"
                    {...register("password")}
                  />
                </div>
              )}
              <div className="title-alone">Roles</div>
              {rolesSelect(allRoles)}
              {isButtonCreated === true && <button className="button-upload">Créer </button>}
              {isButtonCreated === false && <button className="button-upload">Modifier</button>}
            </form>
          </div>
        </div>
      </Modal>
      <div className="view-array">
        <Container sx={{ maxWidth: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manager Users
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleOpenCreateUser()}
              sx={{ background: "rgb(34, 10, 110)" }}
              startIcon={<AddIcon />}
            >
              New User
            </Button>
          </Stack>

          <Card>
            <ArrayListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar sx={{ height: 400 }}>
              <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 400 }}>
                <Table sx={{ height: 400 }}>
                  <ArrayListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredCompagny
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index: number) => {
                        const { id, username, mail, profiles, roles, createdAt } = row;
                        const selectedUser = selected.indexOf(username) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, username)} />
                            </TableCell>

                            <TableCell align="left">{id}</TableCell>

                            <TableCell align="left">{username}</TableCell>

                            <TableCell align="left">{mail}</TableCell>

                            <TableCell align="left">{profiles.length}</TableCell>

                            <TableCell align="left">{roles.length}</TableCell>
                            <TableCell align="left">{createdAt}</TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={handleAnchorEl}>
                                <MoreVertIcon onClick={() => handleOpenMenu(row)} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 35]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <Popover
          open={Boolean(open)}
          anchorEl={openAnchoEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem sx={{ color: "warning.main" }} onClick={() => handleOpenModifyUser(userChangeForUpdateOrSup)}>
            <ChangeCircleIcon sx={{ mr: 2 }} />
            update
          </MenuItem>
          <MenuItem sx={{ color: "error.main" }} onClick={() => handleDeleteUser()}>
            <DeleteIcon sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default User;
